
import FormGroup from "@/components/FormGroup.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    FormGroup,
  },
})
export default class FormModalChangePasswordComponent extends Vue {
  loading!: boolean;
  show!: boolean;
  id!: string;
  type!: string;

  currentpassword!: string;
  password!: string;
  confirmPassword!: string;

  role_id!: string;
  display_name!: string;
  status!: string;

  data() {
    return {
      loading: false,
      currentpassword: "",
      password: "",
      confirmPassword: "",

      role_id: "",
      display_name: "",
      status: "",
    };
  }

  mounted() {
    this.initData();
  }

  async initData() {
    if (this.id) {
      await this.getStaffById();
    }
  }

  async getStaffById() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/staff/${this.id}`);
      if (resp?.status !== 200) {
        return this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: resp?.data?.status?.message,
          showConfirmButton: false,
        });
      } else {
        let item = resp?.data?.data || null;
        this.role_id = item?.role_id || "";
        this.display_name = item?.display_name || "";
        this.status = item?.status || "";
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSubmit() {
    if (this.password !== this.confirmPassword) {
      return this.$swal.fire({
        icon: "warning",
        text: "รหัสผ่านไม่ตรงกัน",
        timer: 1500,
        showConfirmButton: false,
      });
    }

    if (this.type === "owner") {
      this.loading = true;
      await this.$axios
        .post(`/admin/api/v1/auth/change-password`, {
          password: this.currentpassword,
          new_password: this.password,
        })
        .then(() => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            text: "เปลี่ยนรหัสผ่านสำเร็จ",
            timer: 1500,
            showConfirmButton: false,
          });
          this.$emit("reload");
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            text: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = true;
      await this.$axios
        .patch(`/admin/api/v1/staff/${this.id}`, {
          role_id: this.role_id,
          display_name: this.display_name,
          password: this.password,
          status: this.status,
        })
        .then(() => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            text: "เปลี่ยนรหัสผ่านสำเร็จ",
            timer: 1500,
            showConfirmButton: false,
          });
          this.$emit("reload");
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            text: "รหัสผ่านต้องประกอบด้วย a-z, A-Z และ 0-9 อย่างน้อย 8 ตัวอักษร",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
