
import FormGroup from "@/components/FormGroup.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  components: {
    FormGroup,
  },
})
export default class FormModalComponent extends Vue {
  loading!: boolean;
  show!: boolean;
  id!: string;
  title!: string;
  restoreId!: string;

  data() {
    return {
      loading: false,
      resotreId: "",
    };
  }

  mounted() {
    //   this.initData();
  }

  async initData() {
    if (this.id) {
      //  await this.getPopup();
    }
  }

  onSubmit(id: any) {
    // console.log(id);
    this.$emit("reload", id);
  }
}
