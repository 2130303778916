
import FormGroup from "@/components/FormGroup.vue";
import FormModalPopup from "@/components/FormModal/Popup.vue";
import FormModalRestore from "@/components/FormModal/Restore.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    FormGroup,
    Switch,
    FormModalPopup,
    FormModalRestore,
  },
})
export default class SettingWebPopupComponent extends Vue {
  items: any;
  popupIsActive!: boolean;
  slideIsActive!: boolean;
  PopupId!: string;
  showModal!: boolean;
  showModalRestore!: boolean;
  restoreId!: string;
  ui!: {
    carousel: boolean;
    modals: boolean;
  };

  data() {
    return {
      showModal: false,
      showModalRestore: false,
      popupIsActive: true,
      slideIsActive: true,
      PopupId: "",
      restoreId: "",
      ui: {
        carousel: false,
        modals: false,
      },
      items: [
        {
          id: "123e4567-e89b-12d3-a456-426614174000",
          name: "Popup 1",
          link: "https://www.google.com",
          image_url: "",
          image_alt: "Image alt",
          sort_order: 1,
          location: "ENABLED",
          status: "ENABLED",
          created_at: "2021-01-01T00:00:00.000Z",
          updated_at: "2021-01-01T00:00:00.000Z",
          deleted_at: "2021-01-01T00:00:00.000Z",
        },
      ],
    };
  }

  mounted() {
    this.getData();
    this.getUiData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/popup`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.items = resp?.data?.data || null;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async getUiData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/ui`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.ui = resp?.data?.data || null;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async changePopup() {
    let checkModals = false;
    if (this.ui.modals === false) {
      checkModals = true;
    }
    if (this.ui.modals === true) {
      checkModals = false;
    }
    try {
      const resp = await this.$axios.patch(`/admin/api/v1/setting/ui`, {
        carousel: this.ui.carousel,
        modals: checkModals,
      });
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getUiData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSubmit() {
    try {
      const resp = await this.$axios.post(`/admin/api/v1/popup`, this.items);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onDelete(id: any) {
    this.$swal
      .fire({
        title: "ยืนยันการลบ Popup?",
        showCancelButton: true,
        icon: "info",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      })
      .then(async (result: any) => {
        if (result.isConfirmed) {
          try {
            const resp = await this.$axios.delete(`/admin/api/v1/popup/${id}`);
            if (resp?.status !== 200) {
              this.$store.dispatch("swalToast", {
                icon: "warning",
                text: resp?.data?.status?.message,
              });
              console.log(resp?.status);
            } else {
              this.$swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                text: "Delete Success",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getData();
            }
          } catch (error: any) {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: error?.response?.data?.error?.message || "",
            });
          }
        }
      });
  }

  async onRestore(id: any) {
    try {
      const resp = await this.$axios.put(`/admin/api/v1/popup/${id}/restore`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Restore Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSort() {
    let sortedIds: Array<any> = [];
    for (let index = 0; index < this.items.length; index++) {
      const id = this.items[index].id;
      sortedIds.push(id);
    }
    try {
      const resp = await this.$axios.put(`/admin/api/v1/popup/sort`, {
        sorted_ids: sortedIds,
      });
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Restore Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  onDragStart(event: any, index: any) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("startIndex", index);
  }
  onDrop(event: any, items: any, endIndex: any) {
    const startIndex = event.dataTransfer.getData("startIndex");
    var raw = items[startIndex];
    items[startIndex] = items[endIndex];
    items[endIndex] = raw;
  }
}
