
import { Options, Vue } from "vue-class-component";
import VueMultiselect from "vue-multiselect";
import { ColorPicker } from "vue3-colorpicker";

@Options({
  components: {
    VueMultiselect,
    ColorPicker,
  },
  props: {
    dataModel: [String, Number, Array, Object, null],
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "input",
    },
    back: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: 0,
    },
    selectLabel: {
      type: String,
      default: "text",
    },
    selectValue: {
      type: String,
      default: "value",
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    isAllowEmpty: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    dataModel: {
      handler() {
        this.initDate();
      },
      deep: true,
    },
    rawDataModel: {
      handler() {
        this.$emit("return", this.rawDataModel);
      },
      deep: true,
    },
    rawDataObject: {
      handler() {
        let val =
          (this.rawDataObject && this.rawDataObject[this.selectValue]) ||
          this.selectValue;
        this.$emit("return", val);
      },
      deep: true,
    },
  },
})
export default class FormGroupComponent extends Vue {
  dataModel!: any;
  placeholder!: string;
  icon!: string;
  type!: string;
  back!: boolean;
  isDisabled!: boolean;
  options!: Array<any>;
  min!: number;
  selectLabel!: string;
  selectValue!: string;
  isSearchable!: boolean;
  isAllowEmpty!: boolean;
  showPassword!: boolean;
  rawDataModel!: any;
  rawOptions!: Array<any>;
  rawDataObject!: any;

  data() {
    return {
      showPassword: false,
      rawDataModel: null,
      rawDataObject: {},
      rawOptions: [],
    };
  }

  created() {
    this.initDate();
  }

  initDate() {
    this.rawDataModel = this.dataModel;
    this.rawOptions = this.options || [];
    if (this.type === "selectOne") {
      this.rawDataObject = this.rawOptions.find(
        (e) => e[this.selectValue] == this.dataModel
      );
    }
  }
  addTag(newTag: string) {
    let tag = {
      text: newTag,
      value: newTag,
    };
    if (!this.rawDataModel) {
      this.rawDataModel = [];
    }
    this.rawDataModel.push(tag);
    this.rawOptions.push(tag);
  }
}
