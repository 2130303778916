import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Auth from "../middleware/auth";

import HomeView from "../views/Home.vue";
import LoginView from "../views/Login.vue";

import ManageEmployeeView from "../views/ManageEmployee.vue";
import SettingBankView from "../views/SettingBank.vue";
import SettingMinigameView from "../views/SettingMinigame.vue";
import SettingWebView from "../views/SettingWeb.vue";
//marketing
import BrokerView from "../views/marketing/broker.vue";
import CashBackView from "../views/marketing/Cashback.vue";
import CouponView from "../views/marketing/Coupon.vue";
import FreeCreditView from "../views/marketing/FreeCredit.vue";
import PartnerView from "../views/marketing/Partner.vue";
import PromotionView from "../views/marketing/Promotion.vue";
import ReferralView from "../views/marketing/Referral.vue";
import TrackingView from "../views/marketing/Tracking.vue";
import WheelSpinView from "../views/marketing/WheelSpin.vue";
//member
import EditCustomerView from "../views/member/customer/EditCustomerView.vue";
import MemberCustomerView from "../views/member/customer/indexView.vue";
import EmployeeView from "../views/member/Employee.vue";
//wallet
import BagView from "../views/wallet/Bag.vue";
import CreditView from "../views/wallet/Credit.vue";
import PendingView from "../views/wallet/Pending.vue";
import StatementView from "../views/wallet/Statement.vue";
import TransactionView from "../views/wallet/Transaction.vue";
import WithdrawView from "../views/wallet/Withdraw.vue";
//report-customer
import CustomerAmountView from "../views/report/customer/Amount.vue";
import CustomerAmountDivisionView from "../views/report/customer/AmountDivision.vue";
import CustomerFirstDepositView from "../views/report/customer/FirstDeposit.vue";
import CustomerPlayingView from "../views/report/customer/FirstDepositPlay.vue";
import CustomerRegisterView from "../views/report/customer/Register.vue";
import CustomerRegisterWorkView from "../views/report/customer/RegisterWork.vue";
import CustomerStatisticsView from "../views/report/customer/Statistics.vue";
//report-employee
import EmployeeEditLogView from "../views/report/employee/EditLog.vue";
import EmployeeOpenWebPageView from "../views/report/employee/OpenWebPage.vue";
import EmployeeTransactionView from "../views/report/employee/Transaction.vue";
//report-account
import AccountBillCycleView from "../views/report/account/BillCycle.vue";
import AccountSeparateView from "../views/report/account/Separate.vue";
import AccountSummaryV2View from "../views/report/account/SummaryV2.vue";
import AccountTransferManualView from "../views/report/account/TransferManual.vue";
import AccountTransferTimeView from "../views/report/account/TransferTime.vue";
//log
import LogLineNotifyView from "../views/log/LineNotify.vue";
import LogSmsView from "../views/log/Sms.vue";
import LogTelegramNotifyView from "../views/log/TelegramNotify.vue";
//seo
import SeoArticleView from "../views/seo/Article.vue";
import SeoMainMenuView from "../views/seo/MainMenu.vue";
import SeoMainPageView from "../views/seo/MainPage.vue";
import SeoMenuFooterView from "../views/seo/MenuFooter.vue";
import SeoTagView from "../views/seo/Tag.vue";
import SeoWebPageTextView from "../views/seo/WebPageText.vue";

import AddRole from "../views/AddRole.vue";
import PageNotFound from "../views/PageNotFound.vue";
import SettingDepositWithdraw from "../views/SettingDepositWithdraw.vue";
import SettingRole from "../views/SettingRole.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    // path: "/:locale",
    name: "home",
    // component: HomeView, // hide menu
    component: SettingWebView,
    meta: {
      middleware: Auth,
    },
    // children: [
    //   {
    //     path: "",
    //     name: "home",
    //     component: HomeView,
    //   },
    // ],
  },
  // login
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  //marketing
  // {
  //   path: "/marketing/cashback",
  //   name: "marketing-cashback",
  //   component: CashBackView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/coupon",
  //   name: "marketing-coupon",
  //   component: CouponView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/free-credit",
  //   name: "marketing-free-credit",
  //   component: FreeCreditView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/broker",
  //   name: "marketing-broker",
  //   component: BrokerView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/partner",
  //   name: "marketing-partner",
  //   component: PartnerView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/promotion",
  //   name: "marketing-promotion",
  //   component: PromotionView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/referral",
  //   name: "marketing-referral",
  //   component: ReferralView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/tracking",
  //   name: "marketing-tracking",
  //   component: TrackingView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/marketing/wheelspin",
  //   name: "marketing-wheelspin",
  //   component: WheelSpinView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // //member
  // {
  //   path: "/member/customer",
  //   name: "member-customer",
  //   component: MemberCustomerView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/member/customer/edit-customer/:id?",
  //   name: "edit-customer",
  //   component: EditCustomerView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/member/employee",
  //   name: "member-employee",
  //   component: EmployeeView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // //wallet
  // {
  //   path: "/wallet/bag",
  //   name: "wallet-bag",
  //   component: BagView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/wallet/credit",
  //   name: "wallet-credit",
  //   component: CreditView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/wallet/pending",
  //   name: "wallet-pending",
  //   component: PendingView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/wallet/statement",
  //   name: "wallet-statement",
  //   component: StatementView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/wallet/transaction",
  //   name: "wallet-transaction",
  //   component: TransactionView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/wallet/withdraw",
  //   name: "wallet-withdraw",
  //   component: WithdrawView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },

  // //report-customer
  // {
  //   path: "/report/customer/register",
  //   name: "report-customer-register",
  //   component: CustomerRegisterView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/register-work",
  //   name: "report-customer-register-work",
  //   component: CustomerRegisterWorkView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/first-deposit",
  //   name: "report-customer-first-deposit",
  //   component: CustomerFirstDepositView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/first-deposit-play",
  //   name: "report-customer-first-deposit-play",
  //   component: CustomerPlayingView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/logged-in",
  //   name: "report-customer-logged-in",
  //   component: CustomerAmountView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/logged-in-work",
  //   name: "report-customer-logged-in-work",
  //   component: CustomerAmountDivisionView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/customer/deposit-withdrawal-statistics-by-group",
  //   name: "report-customer-deposit-withdrawal-statistics-by-group",
  //   component: CustomerStatisticsView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // //report-employee
  // {
  //   path: "/report/employee/edit-log",
  //   name: "report-employee-edit-log",
  //   component: EmployeeEditLogView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/employee/open-web-page",
  //   name: "report-employee-open-web-page",
  //   component: EmployeeOpenWebPageView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/employee/transaction",
  //   name: "report-employee-transaction",
  //   component: EmployeeTransactionView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // //report-account
  // // {
  // //   path: "/report/account/summary",
  // //   name: "report-account-summary",
  // //   component: AccountSummaryView,
  // //   meta: {
  // //     middleware: Auth,
  // //   },
  // // },
  // {
  //   path: "/report/account/summary",
  //   name: "report-account-summary-v2",
  //   component: AccountSummaryV2View,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/account/separate",
  //   name: "report-account-separate",
  //   component: AccountSeparateView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/account/bill-cycle",
  //   name: "report-account-bill-cycle",
  //   component: AccountBillCycleView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/account/transfer-manual",
  //   name: "report-account-transfer-manual",
  //   component: AccountTransferManualView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/report/account/transfer-time",
  //   name: "report-account-transfer-time",
  //   component: AccountTransferTimeView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // //log
  // {
  //   path: "/log/sms",
  //   name: "log-sms",
  //   component: LogSmsView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/log/line-notify",
  //   name: "log-line-notify",
  //   component: LogLineNotifyView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/log/telegram-notify",
  //   name: "log-telegram-notify",
  //   component: LogTelegramNotifyView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  //seo
  {
    path: "/seo/web-page-text",
    name: "seo-web-page-text",
    component: SeoWebPageTextView,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: "/seo/main-page",
    name: "seo-main-page",
    component: SeoMainPageView,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: "/seo/main-menu",
    name: "seo-main-menu",
    component: SeoMainMenuView,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: "/seo/menu-footer",
    name: "seo-menu-footer",
    component: SeoMenuFooterView,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: "/seo/tag",
    name: "seo-tag",
    component: SeoTagView,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: "/seo/article",
    name: "seo-article",
    component: SeoArticleView,
    meta: {
      middleware: Auth,
    },
  },
  // Setting Web
  {
    path: "/setting-web",
    name: "setting-web",
    component: SettingWebView,
    meta: {
      middleware: Auth,
    },
  },
  // {
  //   path: "/setting-deposit-withdraw",
  //   name: "setting-deposit-withdraw",
  //   component: SettingDepositWithdraw,
  //   meta: {
  //     // middleware: Auth,
  //   },
  // },
  // {
  //   path: "/setting-minigame",
  //   name: "setting-minigame",
  //   component: SettingMinigameView,
  //   meta: {
  //     // middleware: Auth,
  //   },
  // },
  // Manage Employee
  // {
  //   path: "/manage-employee",
  //   name: "manage-employee",
  //   component: ManageEmployeeView,
  //   meta: {
  //     // middleware: Auth,
  //   },
  // },
  // // Setting Bank
  // {
  //   path: "/setting-bank",
  //   name: "setting-bank",
  //   component: SettingBankView,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // // Setting permission
  // {
  //   path: "/setting-role",
  //   name: "setting-role",
  //   component: SettingRole,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // {
  //   path: "/setting-role/add-role",
  //   name: "add-role",
  //   component: AddRole,
  //   meta: {
  //     middleware: Auth,
  //   },
  // },
  // Page not found
  {
    path: "/:catchAll(.*)",
    name: "Page-not-found",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_API),
  routes,
});

function nextFactory(context: any, middleware: any, index: any) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;
