import store from "../store";

export default async function auth({ next }: any) {
  await store.dispatch("setInitDate");
  if (store.state.accessToken) {
    next();
  } else {
    return next("/login");
  }
}
