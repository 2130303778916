import store from "@/store";
import type { AxiosInstance } from "axios";
import type { App } from "vue";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $swal: any;
    $store: any;
    $formatMoney: any;
    $splitDateToArray: any;
    $checkPermission: any;
    $checkPs: any;
    $formatNumber: any;
    $setSticky: any;
    $formatBankChannel: any;
    $formatErrorMessage: any;
    $fbDB: any;
    $fbPrefix: any;
  }
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$fbPrefix = process.env.VUE_APP_ENV;
    app.config.globalProperties.$formatErrorMessage = function (details: any) {
      if (!details) {
        return "An unknown error occurred.";
      }
      if (Array.isArray(details)) {
        return details.map((detail) => Object.values(detail)[0]).join("\n");
      }
      if (typeof details === "object") {
        const errorMessages = [];
        for (const key in details) {
          if (Array.isArray(details[key])) {
            errorMessages.push(
              details[key]
                .map((message: any) => `${key}: ${message}`)
                .join("\n")
            );
          } else {
            errorMessages.push(`${key}: ${details[key]}`);
          }
        }
        return errorMessages.join("\n");
      }
      return Object.values(details)[0];
    };
    app.config.globalProperties.$formatMoney = function (
      amount: number,
      decimalCount: number
    ) {
      const fixedAmount = amount.toFixed(decimalCount);
      const parts = fixedAmount.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    };

    app.config.globalProperties.$splitDateToArray = function (date: any) {
      try {
        const dateTime = new Date(date).toLocaleString("en-GB");
        return (dateTime && dateTime.split(", ")) || [];
      } catch (error) {
        return [];
      }
    };

    app.config.globalProperties.$formatNumber = function (number: any) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    app.config.globalProperties.$checkPermission = function (permission: any) {
      try {
        let status = false;
        const split: Array<string> = permission.split(".") || [];
        const roles: Array<{
          name: string;
          permissions: Array<string>;
        }> = store?.state?.roles || [];
        console.groupCollapsed("UserRole:");
        for (let index = 0; index < roles.length; index++) {
          const role = { ...roles[index] };
          console.log(role.name, { ...role.permissions });
          if (role.name === split[0]) {
            const check = role?.permissions.find((v: any) => {
              return v === split[1];
            });
            if (check !== undefined) {
              status = true;
              break;
            }
          }
        }
        console.groupEnd();
        return status;
      } catch (error) {
        return false;
      }
    };

    app.config.globalProperties.$checkPs = function (ps: any) {
      try {
        if (ps == "noPs") return true;
        const permissions: any = store?.state?.permission || {};
        return permissions[ps] ? true : false;
      } catch {
        return false;
      }
    };

    app.config.globalProperties.$formatBankChannel = function (type: any) {
      try {
        if (type?.includes("APP") === true) {
          return "APP";
        }
        if (type?.includes("SMS") === true) {
          return "SMS";
        }
        if (type?.includes("LINE") === true) {
          return "LINE";
        }
        return type;
      } catch {
        return "-";
      }
    };
  },
};
