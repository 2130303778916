
import EmptyDefault from "@/layouts/empty.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    EmptyDefault,
  },
})
export default class PageNotFoundView extends Vue {
  data() {
    return {};
  }
  mounted() {
    this.alertPage();
  }
  alertPage() {
    this.$swal
      .fire({
        icon: "warning",
        title: "ผิดพลาด",
        text: "ไม่พบหน้า",
        confirmButtonText: "ตกลง",
      })
      .then((result: any) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$router.push("/");
        }
      });
  }
}
