import { createI18n } from "vue-i18n";

const storedLocale = localStorage.getItem("locale");

const i18n: any = createI18n({
  locale: storedLocale || "th", // Set the default locale
  fallbackLocale: "th",
  messages: {
    en: require("@/locales/en.json"),
    th: require("@/locales/th.json"),
  },
});

export default i18n;
