
import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    FormGroup,
    Switch,
  },
  watch: {
    primary_color: {
      handler() {
        this.rgbaToHex(this.primary_color, "primary");
      },
      deep: true,
    },
    secondary_color: {
      handler() {
        this.rgbaToHex(this.secondary_color, "secondary");
      },
      deep: true,
    },
    heading_color: {
      handler() {
        this.rgbaToHex(this.heading_color, "headning");
      },
      deep: true,
    },
    text_color: {
      handler() {
        this.rgbaToHex(this.text_color, "text");
      },
      deep: true,
    },
  },
})
export default class SettingWebAppearanceComponent extends Vue {
  item: any;
  rawItem: any;
  MainLogoIcons: any;
  others: any;
  bandGames: any;
  dashboardIcon: any;

  primary_color!: string;
  secondary_color!: string;
  heading_color!: string;
  text_color!: string;
  sections!: Array<string>;

  backgrounds!: any;
  MainLogoIconss!: any;
  menus!: any;
  populars!: any;
  newGames!: any;
  categorys!: any;
  registerGames!: any;
  homeImages!: any;
  gameBackground!: any;
  categoryBackground!: any;
  buttonBackground!: any;

  data() {
    return {
      // item: null,
      dashboardIcon: [],
      item: {
        primary_color: "#000000",
        secondary_color: "#000000",
        heading_color: "#000000",
        text_color: "#000000",
        sections: ["ContentCenter", "HighlightedGame", "Category"],
        background_desktop: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        background_mobile: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        logo: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        favicon: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        dashboard_icon_rebate: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        dashboard_icon_referral: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        dashboard_icon_wheel: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        dashboard_icon_play: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_slot: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_casino: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_sport: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_fish: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_lotto: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_promotion: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_article: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        menu_icon_contact: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        popular_game_background_image: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        popular_game_image: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        new_game_background: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        new_game_image: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_icon_slot: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_icon_sport: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_icon_casino: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_icon_fish: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        register_icon_phone: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        register_icon_password: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        register_icon_bank: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        home_image_hero: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        home_image_footer1: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        home_image_footer2: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        highlighted_game_background: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        recommended_game_background: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_card: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_casino: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_esport: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_fish: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_lotto: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_slot: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        category_background_sport: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        login_button_background: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        register_button_background: {
          url: "https://dev-to-uploads.s3.amazonaws.com/i/0kkdsj62oz9wnlvgsqpb.png",
          alt: "image alternate text",
          title: "link title",
          caption: "anchor text",
          description: "link description",
        },
        contact1_id: "",
        contact1_url: "",
        contact1_icon: {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        },
        contact2_id: "",
        contact2_url: "",
        contact2_icon: {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        },
      },
      sections: [
        "MainContent",
        "HighlightedGame",
        "Category",
        "Hero",
        "ContentGroup",
        "Promotion",
        "Article",
        "Custom1",
        "Custom2",
      ],
      primary_color: "",
      secondary_color: "",
      heading_color: "",
      text_color: "",
      backgrounds: [],
      MainLogoIcons: [],
      menus: [],
      populars: [],
      newGames: [],
      categorys: [],
      registerGames: [],
      homeImages: [],
      gameBackground: [],
      categoryBackground: [],
      buttonBackground: [],
    };
  }

  mounted() {
    this.getData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/appearance`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item = resp?.data?.data || null;
        this.item.contact1_id = "";
        this.item.contact1_url = "";
        this.item.contact1_icon = {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        };
        this.item.contact2_id = "";
        this.item.contact2_url = "";
        this.item.contact2_icon = {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        };
        this.initData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async getDataSetting() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/general`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        let settingItem = resp?.data?.data || null;
        this.item.contact1_id = settingItem.contact1_id;
        this.item.contact1_url = settingItem.contact1_url;
        this.item.contact1_icon = settingItem.contact1_icon;
        this.item.contact2_id = settingItem.contact2_id;
        this.item.contact2_url = settingItem.contact2_url;
        this.item.contact2_icon = settingItem.contact2_icon;
        this.item.contact1_default = settingItem.contact1_url ? true : false;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  initData() {
    let item = this.item;
    this.getDataSetting();
    this.primary_color = this.item?.primary_color || "";
    this.secondary_color = this.item?.secondary_color || "";
    this.heading_color = this.item?.heading_color || "";
    this.text_color = this.item?.text_color || "";
    // logo
    this.sections = this.item.sections || [
      "MainContent",
      "HighlightedGame",
      "Category",
      "Hero",
      "ContentGroup",
      "Promotion",
      "Article",
      "Custom1",
      "Custom2",
    ];
    this.backgrounds = [];
    this.MainLogoIconss = [];
    this.menus = [];
    this.populars = [];
    this.newGames = [];
    this.categorys = [];
    this.registerGames = [];
    this.homeImages = [];
    this.gameBackground = [];
    this.categoryBackground = [];
    this.buttonBackground = [];

    // gameBackground
    this.gameBackground.push({
      text: "พื้นหลังเกมไฮไลท์ (1506x447)",
      url: item.highlighted_game_background.url || "",
      alt: item.highlighted_game_background.alt || "",
      title: item.highlighted_game_background.title || "",
      caption: item.highlighted_game_background.caption || "",
      description: item.highlighted_game_background.description || "",
    });
    this.gameBackground.push({
      text: "พื้นหลังเกมแนะนำ (360x400)",
      url: item.recommended_game_background.url || "",
      alt: item.recommended_game_background.alt || "",
      title: item.recommended_game_background.title || "",
      caption: item.recommended_game_background.caption || "",
      description: item.recommended_game_background.description || "",
    });

    // categoryBackground
    this.categoryBackground.push({
      text: "พื้นหลังหมวดคาสิโน (270x315)",
      url: item.category_background_casino.url || "",
      alt: item.category_background_casino.alt || "",
      title: item.category_background_casino.title || "",
      caption: item.category_background_casino.caption || "",
      description: item.category_background_casino.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดกีฬา (270x315)",
      url: item.category_background_sport.url || "",
      alt: item.category_background_sport.alt || "",
      title: item.category_background_sport.title || "",
      caption: item.category_background_sport.caption || "",
      description: item.category_background_sport.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดสล็อต (270x315)",
      url: item.category_background_slot.url || "",
      alt: item.category_background_slot.alt || "",
      title: item.category_background_slot.title || "",
      caption: item.category_background_slot.caption || "",
      description: item.category_background_slot.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดยิงปลา (270x315)",
      url: item.category_background_fish.url || "",
      alt: item.category_background_fish.alt || "",
      title: item.category_background_fish.title || "",
      caption: item.category_background_fish.caption || "",
      description: item.category_background_fish.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดไพ่ (270x315)",
      url: item.category_background_card.url || "",
      alt: item.category_background_card.alt || "",
      title: item.category_background_card.title || "",
      caption: item.category_background_card.caption || "",
      description: item.category_background_card.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดหวย (270x315)",
      url: item.category_background_lotto.url || "",
      alt: item.category_background_lotto.alt || "",
      title: item.category_background_lotto.title || "",
      caption: item.category_background_lotto.caption || "",
      description: item.category_background_lotto.description || "",
    });
    this.categoryBackground.push({
      text: "พื้นหลังหมวดอี-สปอร์ต (270x315)",
      url: item.category_background_esport.url || "",
      alt: item.category_background_esport.alt || "",
      title: item.category_background_esport.title || "",
      caption: item.category_background_esport.caption || "",
      description: item.category_background_esport.description || "",
    });
    // buttonBackground
    this.buttonBackground.push({
      text: "พื้นหลังปุ่มเข้าสู่ระบบ (164x32)",
      url: item.login_button_background.url || "",
      alt: item.login_button_background.alt || "",
      title: item.login_button_background.title || "",
      caption: item.login_button_background.caption || "",
      description: item.login_button_background.description || "",
    });
    this.buttonBackground.push({
      text: "พื้นหลังปุ่มสมัครสมาชิก (164x32)",
      url: item.register_button_background.url || "",
      alt: item.register_button_background.alt || "",
      title: item.register_button_background.title || "",
      caption: item.register_button_background.caption || "",
      description: item.register_button_background.description || "",
    });

    // background
    this.backgrounds.push({
      text: "รูปภาพ Background Desktop (1920x1080)",
      url: item.background_desktop.url || "",
      alt: item.background_desktop.alt || "",
      title: item.background_desktop.title || "",
      caption: item.background_desktop.caption || "",
      description: item.background_desktop.description || "",
    });
    this.backgrounds.push({
      text: "รูปภาพ Background Mobile (1080x1920)",
      url: item.background_mobile.url || "",
      alt: item.background_mobile.alt || "",
      title: item.background_mobile.title || "",
      caption: item.background_mobile.caption || "",
      description: item.background_mobile.description || "",
    });

    // logo
    this.MainLogoIcons.push({
      text: "โลโก้ (800x190)",
      url: item.logo.url || "",
      alt: item.logo.alt || "",
      title: item.logo.title || "",
      caption: item.logo.caption || "",
      description: item.logo.description || "",
    });
    this.MainLogoIcons.push({
      text: "favicon (32x32)",
      url: item.favicon.url || "",
      alt: item.favicon.alt || "",
      title: item.favicon.title || "",
      caption: item.favicon.caption || "",
      description: item.favicon.description || "",
    });
    this.MainLogoIcons.push({
      text: "คืนยอดเสีย (130x130)",
      url: item.dashboard_icon_rebate.url || "",
      alt: item.dashboard_icon_rebate.alt || "",
      title: item.dashboard_icon_rebate.title || "",
      caption: item.dashboard_icon_rebate.caption || "",
      description: item.dashboard_icon_rebate.description || "",
    });
    this.MainLogoIcons.push({
      text: "แนะนำเพื่อน (130x130)",
      url: item.dashboard_icon_referral.url || "",
      alt: item.dashboard_icon_referral.alt || "",
      title: item.dashboard_icon_referral.title || "",
      caption: item.dashboard_icon_referral.caption || "",
      description: item.dashboard_icon_referral.description || "",
    });
    this.MainLogoIcons.push({
      text: "วงล้อ (130x130)",
      url: item.dashboard_icon_wheel.url || "",
      alt: item.dashboard_icon_wheel.alt || "",
      title: item.dashboard_icon_wheel.title || "",
      caption: item.dashboard_icon_wheel.caption || "",
      description: item.dashboard_icon_wheel.description || "",
    });
    this.MainLogoIcons.push({
      text: "กล่องสุ่ม (130x130)",
      url: item.dashboard_icon_box.url || "",
      alt: item.dashboard_icon_box.alt || "",
      title: item.dashboard_icon_box.title || "",
      caption: item.dashboard_icon_box.caption || "",
      description: item.dashboard_icon_box.description || "",
    });
    this.MainLogoIcons.push({
      text: "เปิดไพ่ (130x130)",
      url: item.dashboard_icon_card.url || "",
      alt: item.dashboard_icon_card.alt || "",
      title: item.dashboard_icon_card.title || "",
      caption: item.dashboard_icon_card.caption || "",
      description: item.dashboard_icon_card.description || "",
    });
    this.MainLogoIcons.push({
      text: "เข้าเล่นเกม (130x130)",
      url: item.dashboard_icon_play.url || "",
      alt: item.dashboard_icon_play.alt || "",
      title: item.dashboard_icon_play.title || "",
      caption: item.dashboard_icon_play.caption || "",
      description: item.dashboard_icon_play.description || "",
    });

    // menu
    this.menus.push({
      text: "เมนูสล็อต (150x115)",
      url: item.menu_icon_slot.url || "",
      alt: item.menu_icon_slot.alt || "",
      title: item.menu_icon_slot.title || "",
      caption: item.menu_icon_slot.caption || "",
      description: item.menu_icon_slot.description || "",
    });
    this.menus.push({
      text: "เมนูคาสิโน (150x115)",
      url: item.menu_icon_casino.url || "",
      alt: item.menu_icon_casino.alt || "",
      title: item.menu_icon_casino.title || "",
      caption: item.menu_icon_casino.caption || "",
      description: item.menu_icon_casino.description || "",
    });
    this.menus.push({
      text: "เมนูกีฬา (150x115)",
      url: item.menu_icon_sport.url || "",
      alt: item.menu_icon_sport.alt || "",
      title: item.menu_icon_sport.title || "",
      caption: item.menu_icon_sport.caption || "",
      description: item.menu_icon_sport.description || "",
    });
    this.menus.push({
      text: "เมนูยิงปลา (150x115)",
      url: item.menu_icon_fish.url || "",
      alt: item.menu_icon_fish.alt || "",
      title: item.menu_icon_fish.title || "",
      caption: item.menu_icon_fish.caption || "",
      description: item.menu_icon_fish.description || "",
    });
    this.menus.push({
      text: "เมนูหวย (150x115)",
      url: item.menu_icon_lotto.url || "",
      alt: item.menu_icon_lotto.alt || "",
      title: item.menu_icon_lotto.title || "",
      caption: item.menu_icon_lotto.caption || "",
      description: item.menu_icon_lotto.description || "",
    });
    this.menus.push({
      text: "เมนูไพ่ (150x115)",
      url: item.menu_icon_card.url || "",
      alt: item.menu_icon_card.alt || "",
      title: item.menu_icon_card.title || "",
      caption: item.menu_icon_card.caption || "",
      description: item.menu_icon_card.description || "",
    });
    this.menus.push({
      text: "เมนูอีสปอร์ต (150x115)",
      url: item.menu_icon_esport.url || "",
      alt: item.menu_icon_esport.alt || "",
      title: item.menu_icon_esport.title || "",
      caption: item.menu_icon_esport.caption || "",
      description: item.menu_icon_esport.description || "",
    });
    this.menus.push({
      text: "เมนูโปรโมชั่น (150x115)",
      url: item.menu_icon_promotion.url || "",
      alt: item.menu_icon_promotion.alt || "",
      title: item.menu_icon_promotion.title || "",
      caption: item.menu_icon_promotion.caption || "",
      description: item.menu_icon_promotion.description || "",
    });
    this.menus.push({
      text: "เมนูบทความ (150x115)",
      url: item.menu_icon_article.url || "",
      alt: item.menu_icon_article.alt || "",
      title: item.menu_icon_article.title || "",
      caption: item.menu_icon_article.caption || "",
      description: item.menu_icon_article.description || "",
    });
    this.menus.push({
      text: "เมนูติดต่อเรา (150x115)",
      url: item.menu_icon_contact.url || "",
      alt: item.menu_icon_contact.alt || "",
      title: item.menu_icon_contact.title || "",
      caption: item.menu_icon_contact.caption || "",
      description: item.menu_icon_contact.description || "",
    });

    // populars
    this.populars.push({
      text: "พื้นหลังเกมยอดนิยม (1632x459)",
      url: item.popular_game_background_image.url || "",
      alt: item.popular_game_background_image.alt || "",
      title: item.popular_game_background_image.title || "",
      caption: item.popular_game_background_image.caption || "",
      description: item.popular_game_background_image.description || "",
    });
    this.populars.push({
      text: "รูปภาพเกมยอดนิยม (443x383)",
      url: item.popular_game_image.url || "",
      alt: item.popular_game_image.alt || "",
      title: item.popular_game_image.title || "",
      caption: item.popular_game_image.caption || "",
      description: item.popular_game_image.description || "",
    });

    // new games
    this.newGames.push({
      text: "พื้นหลังเกมใหม่ล่าสุด (1632x459)",
      url: item.new_game_background.url || "",
      alt: item.new_game_background.alt || "",
      title: item.new_game_background.title || "",
      caption: item.new_game_background.caption || "",
      description: item.new_game_background.description || "",
    });
    this.newGames.push({
      text: "รูปภาพเกมใหม่ล่าสุด (443x383)",
      url: item.new_game_image.url || "",
      alt: item.new_game_image.alt || "",
      title: item.new_game_image.title || "",
      caption: item.new_game_image.caption || "",
      description: item.new_game_image.description || "",
    });

    // categorys
    this.categorys.push({
      text: "ค่ายเกมกีฬา (179x114)",
      url: item.category_icon_sport.url || "",
      alt: item.category_icon_sport.alt || "",
      title: item.category_icon_sport.title || "",
      caption: item.category_icon_sport.caption || "",
      description: item.category_icon_sport.description || "",
    });
    this.categorys.push({
      text: "ค่ายเกมสล็อต (179x114)",
      url: item.new_game_image.url || "",
      alt: item.new_game_image.alt || "",
      title: item.new_game_image.title || "",
      caption: item.new_game_image.caption || "",
      description: item.new_game_image.description || "",
    });
    this.categorys.push({
      text: "ค่ายเกมคาสิโน (179x114)",
      url: item.category_icon_casino.url || "",
      alt: item.category_icon_casino.alt || "",
      title: item.category_icon_casino.title || "",
      caption: item.category_icon_casino.caption || "",
      description: item.category_icon_casino.description || "",
    });
    this.categorys.push({
      text: "ค่ายเกมยิงปลา (179x114)",
      url: item.category_icon_fish.url || "",
      alt: item.category_icon_fish.alt || "",
      title: item.category_icon_fish.title || "",
      caption: item.category_icon_fish.caption || "",
      description: item.category_icon_fish.description || "",
    });

    // registers
    this.registerGames.push({
      text: "เบอร์มือถือ (260x260)",
      url: item.register_icon_phone.url || "",
      alt: item.register_icon_phone.alt || "",
      title: item.register_icon_phone.title || "",
      caption: item.register_icon_phone.caption || "",
      description: item.register_icon_phone.description || "",
    });
    this.registerGames.push({
      text: "ข้อมูลธนาคาร (260x260)",
      url: item.register_icon_bank.url || "",
      alt: item.register_icon_bank.alt || "",
      title: item.register_icon_bank.title || "",
      caption: item.register_icon_bank.caption || "",
      description: item.register_icon_bank.description || "",
    });
    this.registerGames.push({
      text: "รหัสผ่าน (260x260)",
      url: item.register_icon_password.url || "",
      alt: item.register_icon_password.alt || "",
      title: item.register_icon_password.title || "",
      caption: item.register_icon_password.caption || "",
      description: item.register_icon_password.description || "",
    });

    // home images
    this.homeImages.push({
      text: "รูปภาพเดี่ยว (1506x415)",
      url: item.home_image_hero.url || "",
      alt: item.home_image_hero.alt || "",
      title: item.home_image_hero.title || "",
      caption: item.home_image_hero.caption || "",
      description: item.home_image_hero.description || "",
    });
    this.homeImages.push({
      text: "Content Footer #1 (740x500)",
      url: item.home_image_footer1.url,
      alt: item.home_image_footer1.alt,
      title: item.home_image_footer1.title,
      caption: item.home_image_footer1.caption,
      description: item.home_image_footer1.description,
    });
    this.homeImages.push({
      text: "Content Footer #2 (720x560)",
      url: item.home_image_footer2.url || "",
      alt: item.home_image_footer2.alt || "",
      title: item.home_image_footer2.title || "",
      caption: item.home_image_footer2.caption || "",
      description: item.home_image_footer2.description || "",
    });
  }

  onDragStart(event: any, index: any) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("startIndex", index);
  }
  onDrop(event: any, items: any, endIndex: any) {
    const startIndex = event.dataTransfer.getData("startIndex");
    var raw = items[startIndex];
    items[startIndex] = items[endIndex];
    items[endIndex] = raw;
  }

  async onFileChange(e: any, pointer: string, index: number) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let owner: any = this;
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    const fileName = files[0].name;
    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        owner[pointer][index].url = resp?.data?.data?.url || "";
        owner[pointer][index].title =
          fileName.substring(0, fileName.lastIndexOf(".")) || "";
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  rgbaToHex(rgba: any, text: string) {
    if (rgba?.includes("#") === false) {
      const matches = rgba.match(/\d+/g);
      const r = parseInt(matches[0]);
      const g = parseInt(matches[1]);
      const b = parseInt(matches[2]);

      const componentToHex = (c: any) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      const red = componentToHex(r);
      const green = componentToHex(g);
      const blue = componentToHex(b);

      if (text === "primary") {
        this.primary_color = `#${red}${green}${blue}`;
      }
      if (text === "secondary") {
        this.secondary_color = `#${red}${green}${blue}`;
      }
      if (text === "headning") {
        this.heading_color = `#${red}${green}${blue}`;
      }
      if (text === "text") {
        this.text_color = `#${red}${green}${blue}`;
      }
    }
  }

  async onSubmit() {
    // color
    this.item.primary_color = this.primary_color;
    this.item.secondary_color = this.secondary_color;
    this.item.heading_color = this.heading_color;
    this.item.text_color = this.text_color;
    // bg
    this.item.background_desktop = this.backgrounds[0];
    this.item.background_mobile = this.backgrounds[1];
    // icon
    this.item.logo = this.MainLogoIcons[0];
    this.item.favicon = this.MainLogoIcons[1];
    this.item.dashboard_icon_rebate = this.MainLogoIcons[2];
    this.item.dashboard_icon_referral = this.MainLogoIcons[3];
    this.item.dashboard_icon_wheel = this.MainLogoIcons[4];
    this.item.dashboard_icon_box = this.MainLogoIcons[5];
    this.item.dashboard_icon_card = this.MainLogoIcons[6];
    this.item.dashboard_icon_play = this.MainLogoIcons[7];
    // menu
    this.item.menu_icon_slot = this.menus[0];
    this.item.menu_icon_casino = this.menus[1];
    this.item.menu_icon_sport = this.menus[2];
    this.item.menu_icon_fish = this.menus[3];
    this.item.menu_icon_lotto = this.menus[4];
    this.item.menu_icon_card = this.menus[5];
    this.item.menu_icon_esport = this.menus[6];
    this.item.menu_icon_promotion = this.menus[7];
    this.item.menu_icon_article = this.menus[8];
    this.item.menu_icon_contact = this.menus[9];
    //popular
    this.item.popular_game_background_image = this.populars[0];
    this.item.popular_game_image = this.populars[1];
    // new game
    this.item.new_game_background = this.newGames[0];
    this.item.new_game_image = this.newGames[1];
    // category
    this.item.category_icon_sport = this.categorys[0];
    this.item.new_game_image = this.categorys[1];
    this.item.category_icon_casino = this.categorys[2];
    this.item.category_icon_fish = this.categorys[3];
    // register
    this.item.register_icon_phone = this.registerGames[0];
    this.item.register_icon_bank = this.registerGames[1];
    this.item.register_icon_password = this.registerGames[2];
    //home image
    this.item.home_image_hero = this.homeImages[0];
    this.item.home_image_footer1 = this.homeImages[1];
    this.item.home_image_footer2 = this.homeImages[2];
    // Sections
    this.item.sections = this.sections;
    // gameBackground
    this.item.highlighted_game_background = this.gameBackground[0];
    this.item.recommended_game_background = this.gameBackground[1];
    // categoryBackground
    this.item.category_background_casino = this.categoryBackground[0];
    this.item.category_background_sport = this.categoryBackground[1];
    this.item.category_background_slot = this.categoryBackground[2];
    this.item.category_background_fish = this.categoryBackground[3];
    this.item.category_background_card = this.categoryBackground[4];
    this.item.category_background_lotto = this.categoryBackground[5];
    this.item.category_background_esport = this.categoryBackground[6];
    // buttonBackground
    this.item.login_button_background = this.buttonBackground[0];
    this.item.register_button_background = this.buttonBackground[1];

    if (this.item.contact1_default && this.item.contact1_url == "") {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: "กรุณากรอก icon1 url",
      });
      const element: HTMLElement | null =
        document.getElementById("_setting_icon1");

      this.$nextTick(() => {
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      });
      return;
    }

    try {
      const resp = await this.$axios.patch(
        `/admin/api/v1/setting/appearance`,
        this.item
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.onSubmitSetting();
        this.MainLogoIcons = [];
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onFileIconChange(e: any, type: any) {
    var files = e.target.files || e.dataTransfer.files;
    const fileName = files[0].name;
    // console.log(fileName.substring(0, fileName.lastIndexOf(".")));

    if (!files.length) return;

    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        if (type == "line") {
          this.item.contact1_icon.url = resp?.data?.data?.url || "";
        }
        if (type == "telegram") {
          this.item.contact2_icon.url = resp?.data?.data?.url || "";
        }
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSubmitSetting() {
    let itemSetting = this.item;
    try {
      const resp = await this.$axios.patch(
        `/admin/api/v1/setting/general`,
        itemSetting
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  clearUrl(type: any) {
    this.item.contact1_default = !this.item.contact1_default;
    if (type == 1 && !this.item.contact1_default) {
      this.item.contact1_url = "";
    }
  }
}
