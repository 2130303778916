import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import type { App } from "vue";

export default {
  install: (app: App) => {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FB_API_KEY,
      authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
      projectId: process.env.VUE_APP_FB_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FB_APP_ID,
      measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
    };
    try {
      const ap = initializeApp(firebaseConfig);
      const auth = getAuth(ap);
      const db = getDatabase(ap);

      app.config.globalProperties.$fbAuth = auth;
      app.config.globalProperties.$fbApp = ap;
      app.config.globalProperties.$fbDB = db;
    } catch (error) {
      console.error("firebase connection fail.");
    }
  },
};
