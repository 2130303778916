
import FormGroup from "@/components/FormGroup.vue";
import EmptyDefault from "@/layouts/empty.vue";
import jwtDecode from "jwt-decode";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    EmptyDefault,
    FormGroup,
  },
})
export default class LoginView extends Vue {
  loading!: boolean;
  showPassword!: boolean;
  username!: string;
  password!: string;

  data() {
    return {
      loading: false,
      showPassword: false,
      username: "",
      password: "",
    };
  }

  created() {
    if (this.$store.state.accessToken) {
      this.$router.push("/");
    }
  }

  async onSubmit(event: any) {
    event.preventDefault();

    if (!this.username || !this.password) {
      return this.$swal.fire({
        icon: "error",
        text: "username and password require",
        confirmButtonText: "Confirm",
      });
    }

    try {
      this.loading = true;
      const resp = await this.$axios.post(`/public/api/v1/auth/staff/login`, {
        username: this.username,
        password: this.password,
      });

      const token = resp?.data?.data?.access_token || "";
      const decodedToken: any = jwtDecode(token);

      if (token) {
        await this.$store.dispatch("setCookiesInStore", {
          setAccessToken: ["accessToken", token, decodedToken.exp],
          setUsername: [
            "username",
            decodedToken.data.username,
            decodedToken.exp,
          ],
          setDisplayName: [
            "displayName",
            decodedToken.data.display_name,
            decodedToken.exp,
          ],
          setKind: ["kind", decodedToken.data.kind, decodedToken.exp],
          setUid: ["uid", decodedToken.id, decodedToken.exp],
          setAgentId: ["aid", decodedToken.data.agent_id, decodedToken.exp],
        });
        // this.getPermission(token);
        location.reload();
      }
    } catch (error: any) {
      // this.$swal.fire({
      //   icon: "error",
      //   text: "username or password is incorrect",
      //   confirmButtonText: "Confirm",
      // });
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
    this.loading = false;
  }

  // async getPermission(toke: any) {
  //   try {
  //     const token = `Bearer ${toke}`;
  //     const baseURL = process.env.VUE_APP_USER_API || "";
  //     const resp = await axios.get(`${baseURL}/admin/api/v1/auth/profile`, {
  //       headers: {
  //         accept: "application/json",
  //         Authorization: token,
  //       },
  //     });
  //     if (resp?.status !== 200) {
  //       this.$store.dispatch("swalToast", {
  //         icon: "warning",
  //         text: resp?.data?.status?.message,
  //       });
  //     } else {
  //       let permissions: any = resp?.data?.data?.permissions;
  //       this.$store.dispatch("setInitDate", {
  //         setRolesPermission: permissions,
  //       });
  //       location.reload();
  //       // this.$router.push("/");
  //     }
  //   } catch (error: any) {
  //     console.log(error.toJSON());
  //   }
  // }
}
