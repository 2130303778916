import Swal from "sweetalert2";
import { createStore } from "vuex";

export default createStore({
  state: {
    accessToken: "",
    username: "",
    displayName: "",
    kind: "",
    uid: "",
    roles: [],
    permission: {},
    rolesPermission: [],
    aid: "",
    locale: "",
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    username(state) {
      return state.username;
    },
    displayName(state) {
      return state.displayName;
    },
    kind(state) {
      return state.kind;
    },
    uid(state) {
      return state.uid;
    },
    roles(state) {
      return state.roles;
    },
    permissions(state) {
      return state.permission;
    },
    rolesPermission(state) {
      return state.rolesPermission;
    },
    aid(state) {
      return state.aid;
    },
    locale(state) {
      return state.locale;
    },
  },
  mutations: {
    setAccessToken(state, payloads) {
      state.accessToken = payloads;
    },
    setUsername(state, payloads) {
      state.username = payloads;
    },
    setDisplayName(state, payloads) {
      state.displayName = payloads;
    },
    setKind(state, payloads) {
      state.kind = payloads;
    },
    setUid(state, payloads) {
      state.uid = payloads;
    },
    setRoles(state, payloads) {
      state.roles = payloads;
    },
    setPermissions(state, payloads) {
      state.permission = payloads;
    },
    setRolesPermission(state, payload) {
      state.rolesPermission = payload;
    },
    setAid(state, payload) {
      state.aid = payload;
    },
    setLocale(state, payload) {
      state.locale = payload;
    },
  },
  actions: {
    setInitDate({ commit }, payloads) {
      const accessToken = dcodedCookie("accessToken") || null;
      const username = dcodedCookie("username") || null;
      const displayName = dcodedCookie("displayName") || null;
      const kind = dcodedCookie("kind") || null;
      const uid = dcodedCookie("uid") || null;
      const aid = dcodedCookie("aid") || null;
      // const permission = payloads || null;
      if (accessToken) {
        commit("setAccessToken", accessToken);
        commit("setUsername", username);
        commit("setDisplayName", displayName);
        commit("setKind", kind);
        commit("setUid", uid);
        commit("setAid", aid);
        // if (permission) {
        //   console.log(permission);
        //   commit("setPermissions", permission);
        // }

        // setAgentId: ["aid", decodedToken.data.agent_id, decodedToken.exp],
        // const decodedToken: any = jwtDecode(accessToken);

        let roles: any = [];
        roles = payloads;

        const rawRole: any = [];
        for (let index = 0; index < roles?.length; index++) {
          const role = roles[index];
          const split: any = role.split(".") || [];

          const findRole: any = rawRole.find((v: any) => {
            return v.name === split[0];
          });
          if (findRole === undefined) {
            rawRole.push({
              name: split[0],
              permissions: [],
            });
          }

          const reCheckRole: any = rawRole.find((v: any) => {
            return v.name === split[0];
          });
          if (reCheckRole !== undefined) {
            reCheckRole.permissions.push(split[1]);
          }
        }

        commit("setRoles", rawRole);
        // const ps = decodedToken.roles.reduce(
        //   (a: any, v: string) => ({ ...a, [v]: true }),
        //   {}
        // );

        // commit("setPermissions", ps);
        // commit("setAgentId", id);
      }
    },
    setCookiesInStore({ commit }, payloads) {
      for (let index = 0; index < Object.keys(payloads).length; index++) {
        const key = Object.keys(payloads)[index];
        const type = payloads[key][0] || null;
        const value = payloads[key][1] || "";
        const time = payloads[key][2] || 0;
        if (type) {
          setCookie(type, value, time);
          commit(key, value);
        }
      }
    },
    async clearAllCookies({ commit }) {
      return await new Promise((reslove) => {
        document.cookie.split(";").forEach(function (c) {
          document.cookie =
            c.trim().split("=")[0] +
            "=;" +
            "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        });
        commit("setAccessToken", null);
        reslove(true);
        // const cookies = document.cookie.split(";");
        // console.log(cookies);
        // for (let i = 0; i < cookies.length; i++) {
        //   // const cookie = cookies[i];
        //   // const eqPos = cookie.indexOf("=");
        //   // const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //   // document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // }
      });
    },

    async swalToast({ commit }, { swalIcon = "success", text = "message" }) {
      await Swal.fire({
        toast: true,
        position: "top-end",
        icon: swalIcon,
        title: text,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    },
  },

  modules: {},
});

function setCookie(name: string, value: string | number, secound: number) {
  let expires = "";
  if (secound) {
    const date = new Date();
    date.setTime(date.getTime() + secound * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function dcodedCookie(cookieName: string) {
  const name = cookieName + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
