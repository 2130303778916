import type { App } from "vue";

export default {
  install: (app: App) => {
    app.config.globalProperties.$setSticky = (
      tableId = "tableWrapperRef",
      headerId = "headerWrapperRef",
      headerTop = 61
    ) => {
      const scrolling = document.getElementsByClassName("layout__content")[0];
      const tableWrapperRef = document.getElementById(tableId);
      const headerWrapperRef = document.getElementById(headerId);
      if (headerWrapperRef) {
        headerWrapperRef.style.removeProperty("transform");

        const translate = () => {
          if (tableWrapperRef && headerWrapperRef) {
            const { scrollTop: scroll } = scrolling;
            const header = headerWrapperRef;
            const table = tableWrapperRef;

            const offsetTop = table.offsetTop;
            if (scroll + headerTop > offsetTop) {
              const yTranslation = Math.floor(
                Math.abs(scroll + headerTop - offsetTop)
              );
              header.style.setProperty(
                "transform",
                `translateY(${yTranslation}px)`
              );
            } else {
              header.style.removeProperty("transform");
            }
          }
        };
        scrolling.removeEventListener("scroll", translate);
        scrolling.addEventListener("scroll", translate);
      }
    };
  },
};
