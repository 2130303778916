
import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    FormGroup,
    Switch,
  },
})
export default class SettingWebGeneralComponent extends Vue {
  item: any;
  languages!: any;
  lockActiveLang!: boolean;
  data() {
    return {
      item: {
        site_name: "",
        site_title: "",
        site_keywords: "",
        site_description: "",
        line_id: "",
        line_url: "",
        line_icon: {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: false,
        },
        contact1_id: "",
        contact1_url: "",
        contact1_icon: {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        },
        contact2_id: "",
        contact2_url: "",
        contact2_icon: {
          url: "",
          alt: "",
          title: "",
          caption: "",
          description: "",
          animate: true,
        },
        languages: {
          thai: false,
          // english: false,
          // chinese: false,
          // cambodian: false,
          // lao: false,
          // myanmar: false,
          // vietnamese: false,
          // malay: false,
          // indonesian: false,
          // singaporean: false,
          // japanese: false,
          // korean: false,
        },
        google_analytic_enabled: true,
        google_analytic_code: "",
        google_tag_manager_enabled: true,
        google_tag_manager_code: "",
        google_search_console_enabled: true,
        google_search_console_code: "",
        marquee_enabled: false,
        marquee_text: {
          cambodian: "",
          chinese: "",
          english: "",
          indonesian: "",
          japanese: "",
          korean: "",
          lao: "",
          malay: "",
          myanmar: "",
          singaporean: "",
          thai: "",
          vietnamese: "",
        },
        custom_login_url: "",
        custom_register_url: "",
        v1_domain: "",
      },
      languages: [],
      isActive: false,
      isType: 0,
      lockActiveLang: false,
    };
  }

  mounted() {
    this.getData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/general`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.item = resp?.data?.data || null;
        this.item.marquee_text = {
          cambodian: "",
          chinese: "",
          english: "",
          indonesian: "",
          japanese: "",
          korean: "",
          lao: "",
          malay: "",
          myanmar: "",
          singaporean: "",
          thai: "",
          vietnamese: "",
        };
        this.initData();
        this.getDataSeo();
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        this.$swal
          .fire({
            icon: "warning",
            title: "แจ้งเตือน",
            text: error?.response?.data?.error?.message || "",
            confirmButtonText: "ยืนยัน",
          })
          .then(async (result: any) => {
            if (result.isConfirmed) {
              this.onLogOut();
            }
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.error?.message || "",
        });
      }
    }
  }

  async onLogOut() {
    try {
      await this.$store.dispatch("clearAllCookies");
      this.$router.push("/login");
    } catch (error: any) {
      this.$swal.fire({
        icon: "warning",
        title: "แจ้งเตือน",
        text: error?.response?.data?.error?.message || "",
        confirmButtonText: "ยืนยัน",
      });
    }
  }

  initData() {
    let item = this.item;
    this.languages = [];
    this.languages.push({
      name: "thai",
      isActive: item.languages.thai || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "english",
      isActive: item.languages.english || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "chinese",
      isActive: item.languages.chinese || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "cambodian",
      isActive: item.languages.cambodian || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "lao",
      isActive: item.languages.lao || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "myanmar",
      isActive: item.languages.myanmar || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "vietnamese",
      isActive: item.languages.vietnamese || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "malay",
      isActive: item.languages.malay || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "indonesian",
      isActive: item.languages.indonesian || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "singaporean",
      isActive: item.languages.singaporean || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "japanese",
      isActive: item.languages.japanese || false,
      isActiveMarquee: false,
    });
    this.languages.push({
      name: "korean",
      isActive: item.languages.korean || false,
      isActiveMarquee: false,
    });
    this.activeLangMarqueeDefault();
  }

  async onSubmit() {
    this.item.google_analytic_enabled = true;
    this.item.google_search_console_enabled = true;
    this.item.google_tag_manager_enabled = true;
    this.item.facebook_pixel_enabled = true;
    this.item.languages.thai = this.languages[0].isActive;
    this.item.languages.english = this.languages[1].isActive;
    this.item.languages.chinese = this.languages[2].isActive;
    this.item.languages.cambodian = this.languages[3].isActive;
    this.item.languages.lao = this.languages[4].isActive;
    this.item.languages.myanmar = this.languages[5].isActive;
    this.item.languages.vietnamese = this.languages[6].isActive;
    this.item.languages.malay = this.languages[7].isActive;
    this.item.languages.indonesian = this.languages[8].isActive;
    this.item.languages.singaporean = this.languages[9].isActive;
    this.item.languages.japanese = this.languages[10].isActive;
    this.item.languages.korean = this.languages[11].isActive;

    try {
      const resp = await this.$axios.patch(
        `/admin/api/v1/setting/general`,
        this.item
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        // this.$swal.fire({
        //   toast: true,
        //   position: "top-end",
        //   icon: "success",
        //   text: "Update Success",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // this.getData();
        this.onSubmitSeo();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async onFileChange(e: any, type: any) {
    var files = e.target.files || e.dataTransfer.files;
    const fileName = files[0].name;
    // console.log(fileName.substring(0, fileName.lastIndexOf(".")));

    if (!files.length) return;

    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        if (type == "line") {
          this.item.contact1_icon.url = resp?.data?.data?.url || "";
        }
        if (type == "telegram") {
          this.item.contact2_icon.url = resp?.data?.data?.url || "";
        }
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async getDataSeo() {
    try {
      const resp = await this.$axios.get("/admin/api/v1/setting/seo");
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item.marquee_enabled = resp?.data?.data?.marquee_enabled || null;
        this.item.marquee_text = resp?.data?.data?.marquee_text || null;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async onSubmitSeo() {
    try {
      const resp = await this.$axios.patch(
        "/admin/api/v1/setting/seo",
        this.item
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  getImageUrl = (name: string) => {
    let images = "";
    images = require("../assets/image/" + name + ".png");
    if (!images) {
      require("../assets/logo.png");
    }
    return images;
  };
  resetActiveMarquee(i: any) {
    const newLangs = this.languages.map((lang: any) => ({
      ...lang,
      isActiveMarquee: false,
    }));
    this.languages = newLangs;
    this.languages[i].isActiveMarquee = true;
  }
  activeLangMarqueeDefault() {
    const indexlangActive = this.languages.findIndex(function (
      item: any,
      i: any
    ) {
      return item.isActive;
    });
    this.resetActiveMarquee(indexlangActive);
  }
  countCheckActivelang(index: any) {
    // set Active Lang
    this.languages[index].isActive = !this.languages[index].isActive;
    // find and count active lang
    const found = this.languages.filter((obj: any) => {
      return obj.isActive;
    });
    var checkcount = found.length;
    // check if active = 1 disable switch lang
    if (checkcount == 1) {
      this.lockActiveLang = true;
    } else {
      this.lockActiveLang = false;
    }
    // find 1st active to set active marquee lang text
    const indexlangActive = this.languages.findIndex(function (
      item: any,
      i: any
    ) {
      return item.isActive == true;
    });
    const newLangs = this.languages.map((lang: any) => ({
      ...lang,
      isActiveMarquee: false,
    }));
    this.languages = newLangs;
    this.languages[indexlangActive].isActiveMarquee = true;
  }
}
