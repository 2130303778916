
import FormGroup from "@/components/FormGroup.vue";
import LayoutDefault from "@/layouts/default.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LayoutDefault,
    FormGroup,
  },
})
export default class SeoArticleView extends Vue {
  status!: string;
  search!: string;

  data() {
    return {
      status: "สถานะ",
      search: "",
    };
  }
}
