import axios from "axios";
import type { App } from "vue";
import store from "../store";

type SvcMap = {
  agent: string;
  user: string;
  fallback: string;
};

function getBaseUrl(uri: string, svcMap: SvcMap) {
  const map = {
    "/public/api/v1/auth": svcMap.user,
    "/admin/api/v1/auth": svcMap.user,
    "/admin/api/v1/member": svcMap.user,
    "/admin/api/v1/role": svcMap.user,
    "/admin/api/v1/permission": svcMap.user,
    "/admin/api/v1/staff": svcMap.user,
    "/admin/api/v1/admin": svcMap.user,
    "/admin/api/v1/crm": svcMap.user,
    "/admin/api/v1/marketing": svcMap.user,
    "/admin/api/v1/referral-history": svcMap.user,
    "/public/api/v1/carousel": svcMap.agent,
    "/public/api/v1/popup": svcMap.agent,
    "/admin/api/v1/activity-log": svcMap.agent,
    "/admin/api/v1/agent": svcMap.agent,
    "/admin/api/v1/carousel": svcMap.agent,
    "/admin/api/v1/media": svcMap.agent,
    "/admin/api/v1/popup": svcMap.agent,
    "/admin/api/v1/setting": svcMap.agent,
    "/admin/api/v1/wallet/setting": svcMap.agent,
  };
  type Prefix = keyof typeof map;
  for (const prefix of Object.keys(map) as Prefix[]) {
    if (uri.startsWith(prefix)) {
      return map[prefix];
    }
  }
  return svcMap.fallback;
}

export default {
  install: async (app: App) => {
    if (!store.state.accessToken) {
      await store.dispatch("setInitDate");
    }

    const token = store.state.accessToken
      ? `Bearer ${store.state.accessToken}`
      : "";

    const instance = axios.create({
      baseURL: process.env.VUE_APP_USER_API || "",
      headers: {
        accept: "application/json",
        Authorization: token,
      },
    });

    // Add a request interceptor
    instance.interceptors.request.use(
      function (config: any) {
        config.baseURL = getBaseUrl(config.url, {
          agent: process.env.VUE_APP_AGENT_SVC_API,
          user: process.env.VUE_APP_USER_SVC_API,
          fallback: process.env.VUE_APP_USER_API,
        });
        config.headers["Authorization"] = token;
        config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";
        config.headers["Accept-Language"] = "th";
        return config;
      },
      function (error: any) {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    app.config.globalProperties.$axios = instance;
  },
};
