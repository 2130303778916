
import FormModalChangePassword from "@/components/FormModal/ChangePassword.vue";
import i18n from "@/plugins/i18n";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronDownIcon,
    FormModalChangePassword,
  },
  computed: {
    async faviconUrl() {
      return await this.faviIcon();
    },
  },
})
export default class DefaultLayouts extends Vue {
  show!: boolean;
  screenWidth!: number;
  screenWidthMobile!: number;
  menus!: Array<{
    title: string;
    status: boolean;
    icon: string | null;
    to: string | null;
    show: boolean;
    ps: string | "";
    childs: Array<{
      title: string;
      status: boolean;
      icon: string | null;
      to: string | null;
      show: boolean;
      ps: string | "";
      childs: Array<{
        title: string;
        status: boolean;
        to: string | null;
        show: boolean;
        ps: string | "";
      }>;
    }>;
  }>;
  displayName!: string;
  showModal!: boolean;
  uid!: string;
  logo!: any;
  is_wd_open!: boolean;
  is_dp_open!: boolean;
  faviIcon!: any;
  isLocal!: any;

  created() {
    this.initDate();
    this.getPermission();
  }

  mounted() {
    this.checkLocal(i18n.global.locale || "th");
    this.show = false;
  }

  data() {
    return {
      show: true,
      screenWidth: 0,
      screenWidthMobile: 1280,
      logo: {},
      is_wd_open: false,
      is_dp_open: false,
      menus1: [
        // Dashboard
        {
          title: this.$t("layout.default.menu.dashboard"),
          icon: "fa-light fa-house",
          to: `/`,
          // to: `/${i18n.global.locale}`,
          show: false,
          ps: "noPs",
          childs: [],
        },
        // ลูกค้า
        {
          title: this.$t("layout.default.menu.customer"),
          icon: "fa-light fa-user",
          to: "/member/customer",
          show: false,
          ps: "member.view",
          childs: [],
        },
        // ธุรกรรม
        {
          title: this.$t("layout.default.menu.wallet"),
          icon: "fa-light fa-circle-dollar",
          to: null,
          show: false,
          childs: [
            {
              title: this.$t("layout.default.menu.walletPending"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/pending",
              show: false,
              ps: "deposit.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.walletBag"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/bag",
              show: false,
              ps: "wallet.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.walletCredit"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/credit",
              show: false,
              ps: "credit.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.walletWithdraw"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/withdraw",
              show: false,
              ps: "withdraw.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.walletTransaction"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/transaction",
              show: false,
              ps: "transaction.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.walletStatement"),
              icon: "fa-light fa-circle text-xxs",
              to: "/wallet/statement",
              show: false,
              ps: "statement.view",
              childs: [],
            },
          ],
        },
        // การตลาด
        {
          title: this.$t("layout.default.menu.marketing"),
          icon: "fa-light fa-shop",
          to: null,
          show: false,
          childs: [
            {
              title: this.$t("layout.default.menu.marketingPromotion"),
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/promotion",
              show: false,
              ps: "promotion.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.marketingFreeCredit"),
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/free-credit",
              show: false,
              ps: "free-credit.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.marketingCoupon"),
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/coupon",
              show: false,
              ps: "coupon.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.marketingฺBroker"),
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/broker",
              show: false,
              ps: "marketing.view",
              childs: [],
            },

            {
              title: this.$t("layout.default.menu.marketingCashback"),
              status: true,
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/cashback",
              show: false,
              ps: "return-balance.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.marketingReferral"),
              status: true,
              icon: "fa-light fa-circle text-xxs",
              to: "/marketing/referral",
              show: false,
              ps: "referral.view",
              childs: [],
            },
            // {
            //   title: "ติดตามลูกค้า",
            //   status: false,
            //   icon: "fa-light fa-circle text-xxs",
            //   to: "/marketing/tracking",
            //   show: false,
            //   ps: "crm.view",
            //   childs: [],
            // },
          ],
        },
        // มินิเกม
        {
          title: this.$t("layout.default.menu.marketingMiniGame"),
          icon: "fa-light fa-gamepad",
          to: null,
          show: false,
          childs: [
            {
              title: this.$t("layout.default.menu.marketingMiniGameWheelspin"),
              icon: "fa-light fa-gamepad text-xxs",
              to: "/marketing/wheelspin",
              show: false,
              ps: "minigame.view",
              childs: [],
            },
          ],
        },
        // รายงาน
        {
          title: this.$t("layout.default.menu.report"),
          icon: "fa-light fa-file-chart-column",
          to: null,
          show: false,
          childs: [
            {
              title: this.$t("layout.default.menu.reportCustomer"),
              icon: "fa-light fa-circle text-xxs",
              to: null,
              show: false,
              ps: "",
              childs: [
                // {
                //   title: "การสมัครสมาชิก",
                //   to: "/report/customer/register",
                //   show: false,
                //   ps: "report.member.register.view",
                // },
                // {
                //   title: "การสมัครสมาชิกแยกตามสายงาน",
                //   to: "/report/customer/register-work",
                //   show: false,
                //   ps: "report.member.register-compare.view",
                // },
                {
                  title: this.$t(
                    "layout.default.menu.reportCustomerFirstDeposit"
                  ),
                  to: "/report/customer/first-deposit",
                  show: false,
                  ps: "report.member.daily-deposit.view",
                },
                {
                  title: this.$t(
                    "layout.default.menu.reportCustomerFirstDepositPlay"
                  ),
                  to: "/report/customer/first-deposit-play",
                  show: false,
                  ps: "report.member.potential.view",
                },
                // {
                //   title: "จำนวนลูกค้าที่เข้าสู่ระบบ",
                //   to: "/report/customer/logged-in",
                //   show: false,
                //   ps: "report.member.login.view",
                // },
                // {
                //   title: "จำนวนลูกค้าที่เข้าสู่ระบบแยกตามรายงาน",
                //   to: "/report/customer/logged-in-work",
                //   show: false,
                //   ps: "report.member.login-compare.view",
                // },
                // {
                //   title: "สถิติฝาก-ถอนของลูกค้าแบ่งตามกลุ่ม",
                //   to: "/report/customer/deposit-withdrawal-statistics-by-group",
                //   show: false,
                //   ps: "report.member.high-roller.view",
                // },
              ],
            },
            {
              title: this.$t("layout.default.menu.reportEmployee"),
              icon: "fa-light fa-circle text-xxs",
              to: null,
              show: false,
              ps: "",
              childs: [
                {
                  title: this.$t(
                    "layout.default.menu.reportEmployeeTransaction"
                  ),
                  to: "/report/employee/transaction",
                  show: false,
                  ps: "report.admin.approval.view",
                },
                {
                  title: this.$t(
                    "layout.default.menu.reportEmployeeOpenWebPage"
                  ),
                  status: true,
                  to: "/report/employee/open-web-page",
                  show: false,
                  ps: "report.admin.browse.view",
                },
                {
                  title: this.$t("layout.default.menu.reportEmployeeEditLog"),
                  status: true,
                  to: "/report/employee/edit-log",
                  show: false,
                  ps: "report.admin.change.view",
                },
              ],
            },
            {
              title: this.$t("layout.default.menu.reportAccount"),
              icon: "fa-light fa-circle text-xxs",
              to: null,
              show: false,
              ps: "",
              childs: [
                {
                  title: this.$t("layout.default.menu.reportAccountSummary"),
                  status: true,
                  to: "/report/account/summary",
                  show: false,
                  ps: "report.banking.sum.view",
                },
                // {
                //   title: "สรุปรายการฝาก-ถอน v2",
                //   status: true,
                //   to: "/report/account/summary_v2",
                //   show: false,
                //   ps: "report.banking.sum.view",
                // },
                // {
                //   title: "แยกรายการฝากถอนตามบัญชี",
                //   to: "/report/account/separate",
                //   show: false,
                //   ps: "report.banking.sum-by-bank.view",
                // },
                // {
                //   title: "ตัดรอบบิล",
                //   to: "/report/account/bill-cycle",
                //   show: false,
                //   ps: "report.banking.billing-cycle.view",
                // },
                // {
                //   title: "โอนเงินออก (แมนนวล)",
                //   status: false,
                //   to: "/report/account/transfer-manual",
                //   show: false,
                //   ps: "report.banking.transfer-manual.view",
                // },
                // {
                //   title: "โอนเงินออก (ตั้งเวลา)",
                //   status: false,
                //   to: "/report/account/transfer-time",
                //   show: false,
                //   ps: "report.banking.transfer-scheduled.view",
                // },
              ],
            },
          ],
        },
        // Log
        {
          title: this.$t("layout.default.menu.log"),
          icon: "fa-light fa-clock-rotate-left",
          to: null,
          show: false,
          childs: [
            // {
            //   title: "เติมเดรดิตเอเจนท์",
            //   icon: "fa-light fa-circle text-xxs",
            //   to: "/log/top-up-credit-agent",
            //   show: false,
            //   childs: [],
            // ps: "log.agent-credit.view"
            // },
            {
              title: this.$t("layout.default.menu.logSms"),
              icon: "fa-light fa-circle text-xxs",
              to: "/log/sms",
              show: false,
              ps: "log.sms.view",
              childs: [],
            },
            // {
            //   title: "Line notify",
            //   status: false,
            //   icon: "fa-light fa-circle text-xxs",
            //   to: "/log/line-notify",
            //   show: false,
            //   ps: "log.line-notify.view",
            //   childs: [],
            // },
            // {
            //   title: "Telegram notify",
            //   icon: "fa-light fa-circle text-xxs",
            //   to: "/log/telegram-notify",
            //   show: false,
            // ps: "log.telegram.view",
            //   childs: [],
            // },
          ],
        },
        // ตั้งค่าเว็บ
        {
          title: this.$t("layout.default.menu.setting"),
          icon: "fa-light fa-gear",
          to: null,
          show: false,
          ps: "setting.view",
          childs: [
            {
              title: this.$t("layout.default.menu.settingWeb"),
              icon: "fa-light fa-folder-grid",
              to: "/setting-web",
              show: false,
              ps: "setting.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.settingDepositWithDraw"),
              icon: "fa-light fa-bill-transfer",
              to: "/setting-deposit-withdraw",
              show: false,
              ps: "setting.manage",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.settingMiniGame"),
              icon: "fa-light fa-table-columns",
              to: "/setting-minigame",
              show: false,
              ps: "setting.manage",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.settingSeo"),
              icon: "fa-light fa-folder-grid",
              to: null,
              show: false,
              ps: "setting.view",
              childs: [
                {
                  title: this.$t("layout.default.menu.settingSeoWebPageText"),
                  status: true,
                  icon: "fa-light fa-circle text-xxs",
                  to: "/seo/web-page-text",
                  show: false,
                  ps: "seo.manage",
                  childs: [],
                },
                // {
                //   title: "หน้าหลัก",
                //   icon: "fa-light fa-circle text-xxs",
                //   to: "/seo/main-page",
                //   show: false,
                // ps: "seo.manage",
                //   childs: [],
                // },
                // {
                //   title: "เมนูหลัก",
                //   icon: "fa-light fa-circle text-xxs",
                //   to: "/seo/main-menu",
                //   show: false,
                // ps: "custom-page.manage",
                //   childs: [],
                // },
                // {
                //   title: "เมนูด้านล่าง",
                //   icon: "fa-light fa-circle text-xxs",
                //   to: "/seo/menu-footer",
                //   show: false,
                // ps: "custom-page.manage",
                //   childs: [],
                // },
                // {
                //   title: "Tag",
                //   icon: "fa-light fa-circle text-xxs",
                //   to: "/seo/tag",
                //   show: false,
                // ps: "seo.manage",
                //   childs: [],
                // },
                // {
                //   title: "บทความ",
                //   icon: "fa-light fa-circle text-xxs",
                //   to: "/seo/article",
                //   show: false,
                // ps: "article.manage",
                //   childs: [],
                // },
              ],
            },
          ],
        },
        // จัดการพนักงาน
        {
          title: this.$t("layout.default.menu.settingEmployee"),
          icon: "fa-light fa-user-group",
          to: null,
          show: false,
          ps: "staff.view",
          childs: [
            {
              title: this.$t("layout.default.menu.manageEmployee"),
              icon: "fa-light fa-wrench",
              to: "/manage-employee",
              show: false,
              ps: "staff.view",
              childs: [],
            },
            {
              title: this.$t("layout.default.menu.manageRole"),
              icon: "fa-light fa-wrench",
              to: "/setting-role",
              show: false,
              ps: "role.view",
              childs: [],
            },
          ],
        },
        // จัดการบัญชีธนาคาร
        {
          title: this.$t("layout.default.menu.settingBank"),
          icon: "fa-light fa-building-columns",
          to: "/setting-bank",
          show: false,
          ps: "bank-account.view",
          childs: [],
        },
        // จัดการสิทธิ์พนักงาน
      ],
      displayName: this.$store.state.displayName || "",
      showModal: false,
      uid: this.$store.state.uid || "",
      faviIcon: null,
      isLocal: null,
      menus: [
        {
          title: this.$t("layout.default.menu.settingWeb"),
          icon: "fa-light fa-folder-grid",
          // to: "/setting-web",
          to: "/",
          show: false,
          ps: "setting.view",
          childs: [],
        },
      ],
    };
  }

  get isMobile() {
    return this.screenWidth <= this.screenWidthMobile;
  }
  checkLocal(locale: any) {
    this.isLocal = locale;
    this.$router.push({
      name: this.$route.name || "",
      params: { locale: locale || "th" },
    });
  }
  changeLocale(locale: any) {
    i18n.global.locale = locale;
    localStorage.setItem("locale", locale);
    this.checkLocal(i18n.global.locale);
  }

  async getPermission() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/auth/profile`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        let permissions: any = resp?.data?.data?.permissions;
        this.$store.dispatch("setInitDate", {
          setRolesPermission: permissions,
        });
        if (permissions) {
          const rawRole: any = [];
          for (let index = 0; index < permissions.length; index++) {
            const role = permissions[index];
            const split: any = role.split(".") || [];
            const findRole: any = rawRole.find((v: any) => {
              return v.name === split[0];
            });
            if (findRole === undefined) {
              rawRole.push({
                name: split[0],
                permissions: [],
              });
            }
            const reCheckRole: any = rawRole.find((v: any) => {
              return v.name === split[0];
            });
            if (reCheckRole !== undefined) {
              reCheckRole.permissions.push(split[1]);
            }
          }
          const ps = permissions.reduce(
            (a: any, v: string) => ({ ...a, [v]: true }),
            {}
          );

          this.$store.dispatch("setCookiesInStore", {
            setPermissions: ["permission", ps],
            // setRoles: ["permission", rawRole],
          });
          const path = this.$route.path;
          this.pathPs(path, this.menus);
          if (
            this.$checkPs("setting.view") ||
            this.$checkPs("setting.manage")
          ) {
            this.getLogo();
            this.getSetting();
          }
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        this.$router.push("/");
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "แจ้งเตือน",
          text: error?.response?.data?.error?.message || "",
          confirmButtonText: "ยืนยัน",
        });
      }
    }
  }

  async getLogo() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/appearance`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        // this.item = resp?.data?.data || null;
        let logo = resp?.data?.data?.logo;
        this.logo = logo;
        this.faviIcon = resp?.data?.data?.favicon?.url;
        this.changeFavicon(this.faviIcon);
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "warning",
        title: "แจ้งเตือน",
        text: error?.response?.data?.error?.message || "",
        confirmButtonText: "ยืนยัน",
      });
    }
  }
  changeFavicon(url: any) {
    let favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.setAttribute("rel", "icon");
      favicon.setAttribute("type", "image/png");
      document.head.appendChild(favicon);
    }
    favicon.setAttribute("href", url);
  }
  async getSetting() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/wallet/setting`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        // this.item = resp?.data?.data || null;
        this.is_wd_open = resp?.data?.data?.is_wd_open;
        this.is_dp_open = resp?.data?.data?.is_dp_open;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "warning",
        title: "แจ้งเตือน",
        text: error?.response?.data?.error?.message || "",
        confirmButtonText: "ยืนยัน",
      });
    }
  }

  initDate() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);

    for (let menuIndex = 0; menuIndex < this.menus.length; menuIndex++) {
      const menu = this.menus[menuIndex];

      menu.show = false;
      if (menu.to && menu.to === this.$route.path) {
        menu.show = true;
      }

      for (let index = 0; index < menu.childs.length; index++) {
        const child = menu.childs[index];

        child.show = false;
        if (child.to && child.to === this.$route.path) {
          child.show = true;
          menu.show = true;
        }

        if (child.childs.length) {
          for (
            let childMenuIndex = 0;
            childMenuIndex < child.childs.length;
            childMenuIndex++
          ) {
            const childMenu = child.childs[childMenuIndex];
            childMenu.show = false;
            if (childMenu.to === this.$route.path) {
              menu.show = true;
              child.show = true;
              childMenu.show = true;
            }
          }
        }
      }
    }
  }

  updateScreenWidth() {
    this.screenWidth = window.innerWidth;
    this.show = false;
    if (this.screenWidth > this.screenWidthMobile) {
      this.show = true;
    }
  }

  routerLink(url: string | null) {
    if (url) {
      this.$router.push(url);
    }
  }

  async onLogOut() {
    try {
      await this.$store.dispatch("clearAllCookies");
      this.$router.push("/login");

      // const cookies = document.cookie.split(";");
      // console.log("cookies", cookies);
      // location.reload();
    } catch (error: any) {
      this.$swal.fire({
        icon: "warning",
        title: "แจ้งเตือน",
        text: error?.response?.data?.error?.message || "",
        confirmButtonText: "ยืนยัน",
      });
    }
  }

  pathPs(path: string, menus: any) {
    if (path && path != "") {
      let obMenu = null;
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.to == path) {
          obMenu = element;
          break;
        }
        if (element?.childs?.length) {
          this.pathPs(path, element.childs);
        }
      }
      if (obMenu && obMenu.ps) {
        if (!this.$checkPs(obMenu.ps)) {
          this.routerLink("/");
        }
      }
    }
  }
  checkPsParent(parent: any) {
    try {
      for (let index = 0; index < parent.childs.length; index++) {
        const element = parent.childs[index];
        if (element?.childs?.length) {
          if (this.checkPsChild(element.childs)) {
            return true;
          }
        } else {
          if (this.$checkPs(element.ps)) {
            return true;
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  }

  checkPsChild(childs: any) {
    try {
      for (let index = 0; index < childs.length; index++) {
        const element = childs[index];
        if (this.$checkPs(element.ps)) {
          return true;
        }
      }
    } catch {
      return false;
    }
  }
}
