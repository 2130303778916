
import LayoutDefault from "@/layouts/default.vue";
import { Options, Vue } from "vue-class-component";

import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";

@Options({
  components: {
    FormGroup,
    LayoutDefault,
    Switch,
  },
})
export default class SeoWebMainBelowView extends Vue {
  isType!: number;
  isLang!: number;
  data() {
    return {
      isLang: 0,
      isType: 0,
    };
  }
}
