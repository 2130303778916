import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-2 mb-2 md:mb-0 w-full" }
const _hoisted_2 = { class: "rounded-b bg-white p-3" }
const _hoisted_3 = {
  key: 0,
  class: "tab-content"
}
const _hoisted_4 = {
  key: 1,
  class: "tab-content"
}
const _hoisted_5 = {
  key: 2,
  class: "tab-content"
}
const _hoisted_6 = {
  key: 3,
  class: "tab-content"
}
const _hoisted_7 = {
  key: 4,
  class: "tab-content"
}
const _hoisted_8 = {
  key: 5,
  class: "tab-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingWebGeneral = _resolveComponent("SettingWebGeneral")!
  const _component_SettingWebAppearance = _resolveComponent("SettingWebAppearance")!
  const _component_SettingWebCarousel = _resolveComponent("SettingWebCarousel")!
  const _component_SettingWebImagePopup = _resolveComponent("SettingWebImagePopup")!
  const _component_SettingWebGame = _resolveComponent("SettingWebGame")!
  const _component_SettingWebContent = _resolveComponent("SettingWebContent")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 0 && 'active bg-white') || 'border-b']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isType = 0))
        }, " ทั่วไป ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 1 && 'active bg-white') || 'border-b']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isType = 1))
        }, " รูปแบบ ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 2 && 'active bg-white') || 'border-b']),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isType = 2))
        }, " สไลด์ ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 3 && 'active bg-white') || 'border-b']),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isType = 3))
        }, " ป๊อปอัพ ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 4 && 'active bg-white') || 'border-b']),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isType = 4))
        }, " เกม ", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["tab-button md:rounded-t-lg bg-gray-300", (_ctx.isType === 5 && 'active bg-white') || 'border-b']),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isType = 5))
        }, " content ", 2)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isType === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SettingWebGeneral)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isType === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_SettingWebAppearance)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isType === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_SettingWebCarousel)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isType === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_SettingWebImagePopup)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isType === 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_SettingWebGame)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isType === 5)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_SettingWebContent)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}