
import FormGroup from "@/components/FormGroup.vue";
import FormModalCarousel from "@/components/FormModal/Carousel.vue";
import FormModalRestore from "@/components/FormModal/Restore.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    FormGroup,
    Switch,
    FormModalCarousel,
    FormModalRestore,
  },
})
export default class SettingWebCarouselComponent extends Vue {
  loading!: boolean;
  items: any;
  popupIsActive!: boolean;
  slideIsActive!: boolean;
  CarouselId!: string;
  restoreId!: string;
  showModal!: boolean;
  showModalRestore!: boolean;

  ui!: {
    carousel: boolean;
    modals: boolean;
  };

  data() {
    return {
      loading: false,
      showModal: false,
      showModalRestore: false,
      popupIsActive: true,
      slideIsActive: true,
      CarouselId: "",
      restoreId: "",
      ui: {
        carousel: false,
        modals: false,
      },
      items: [
        {
          id: "123e4567-e89b-12d3-a456-426614174000",
          name: "Carousel 1",
          link: "https://www.google.com",
          image_url: "",
          image_alt: "Image alt",
          sort_order: 1,
          status: "ENABLED",
          created_at: "2021-01-01T00:00:00.000Z",
          updated_at: "2021-01-01T00:00:00.000Z",
          deleted_at: "2021-01-01T00:00:00.000Z",
        },
      ],
    };
  }

  mounted() {
    this.loading = true;
    this.getData();
    this.getUiData();
    this.$setSticky("tableWrapperRef", "headerWrapperRef");
  }

  async getUiData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/ui`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.ui = resp?.data?.data || null;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/carousel`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.items = resp?.data?.data || null;
        this.loading = false;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async changeCarousel() {
    let checkCarousel = false;
    if (this.ui.carousel === false) {
      checkCarousel = true;
    }
    if (this.ui.carousel === true) {
      checkCarousel = false;
    }
    try {
      const resp = await this.$axios.patch(`/admin/api/v1/setting/ui`, {
        carousel: checkCarousel,
        modals: this.ui.modals,
      });
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getUiData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onDelete(id: any) {
    try {
      const resp = await this.$axios.delete(`/admin/api/v1/carousel/${id}`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Delete Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onRestore(id: any) {
    try {
      const resp = await this.$axios.put(
        `/admin/api/v1/carousel/${id}/restore`
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Restore Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSort() {
    let sortedIds: Array<any> = [];
    for (let index = 0; index < this.items.length; index++) {
      const id = this.items[index].id;
      sortedIds.push(id);
    }

    try {
      const resp = await this.$axios.put(`/admin/api/v1/carousel/sort`, {
        sorted_ids: sortedIds,
      });
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Restore Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  onDragStart(event: any, index: any) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("startIndex", index);
  }
  onDrop(event: any, items: any, endIndex: any) {
    const startIndex = event.dataTransfer.getData("startIndex");
    var raw = items[startIndex];
    items[startIndex] = items[endIndex];
    items[endIndex] = raw;
  }
}
