import { QuillEditor } from "@vueup/vue-quill";
// const QuillEditor = () =>
//   import("@vueup/vue-quill").then(({ QuillEditor }) => QuillEditor);
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Tailwindcss Css
import "./assets/css/tailwindcss.css";
// Main Scss
import "@vuepic/vue-datepicker/dist/main.css";
import "sweetalert2/src/sweetalert2.scss";
import "vue3-colorpicker/style.css";
import "./assets/scss/main.scss";
import axios from "./plugins/axios";
import firebase from "./plugins/firebase";
import i18n from "./plugins/i18n";
import main from "./plugins/main";
import sweetalert2 from "./plugins/sweetalert2";
import table from "./plugins/table";
// plug-in
import VueDatePicker from "@vuepic/vue-datepicker";
// const VueDatePicker = () => import("@vuepic/vue-datepicker");

const app = createApp(App);

app.component("VueDatePicker", VueDatePicker);
app.component("QuillEditor", QuillEditor);

app.use(axios);
app.use(sweetalert2);
app.use(table);
app.use(main);
app.use(firebase);
app.use(i18n);

app.use(store).use(router).mount("#app");
