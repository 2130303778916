
import FormGroup from "@/components/FormGroup.vue";
import LayoutDefault from "@/layouts/default.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LayoutDefault,
    FormGroup,
    Switch,
  },
})
export default class SeoTagView extends Vue {
  tag!: string;
  isActive!: boolean;

  data() {
    return {
      tag: null,
      isActive: false,
    };
  }
}
