
import FormGroup from "@/components/FormGroup.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    FormGroup,
  },
})
export default class FormModalComponent extends Vue {
  loading!: boolean;
  show!: boolean;
  id!: string;

  item!: any;
  seleted!: any;
  statusOptions!: any;

  data() {
    return {
      loading: false,
      item: {
        name: "",
        link: "",
        image_url: "",
        image_alt: "",
        sort_order: 1,
        status: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      seleted: null,
      statusOptions: [
        { text: "เปิดใช้งาน", value: "ENABLED" },
        { text: "ปิดใช้งาน", value: "DISABLED" },
      ],
    };
  }

  mounted() {
    this.initData();
  }

  async initData() {
    if (this.id) {
      await this.getCarousel();
    }
  }

  async getCarousel() {
    try {
      this.loading = true;
      const resp = await this.$axios.get(`/admin/api/v1/carousel/${this.id}`);
      if (resp?.status !== 200) {
        return this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: resp?.data?.status?.message,
          showConfirmButton: false,
        });
      } else {
        this.item = resp?.data?.data || [];
        this.seleted = this.statusOptions.find(
          (option: any) => option.value === this.item.status
        );
        this.loading = false;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onCreate() {
    // if (!this.item.link) {
    //   return this.$swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     icon: "warning",
    //     text: "กรุณากรอกลิงค์",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    // }
    // const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    // const isValidURL: boolean = urlPattern.test(this.item.link);

    // if (!isValidURL) {
    //   return this.$swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     icon: "warning",
    //     text: "กรุณากรอกลิงค์ให้ถูกต้อง",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    // }
    // if (!this.item.link) {
    //   return this.$swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     icon: "warning",
    //     text: "กรุณากรอกลิงค์",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    // }
    if (!this.seleted) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณาเลือกสถานะ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.image_url) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณาเพิ่มรูปภาพ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.name) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณากรอกชื่อรูปภาพ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.image_alt) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณากรอก ALT",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    this.loading = true;
    await this.$axios
      .post(`/admin/api/v1/carousel`, {
        name: this.item.name,
        link: this.item.link,
        image_url: this.item.image_url,
        image_alt: this.item.image_alt,
        status: this.seleted.value,
      })
      .then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "แก้ไข Carousel สำเร็จ",
          timer: 1500,
          showConfirmButton: false,
        });
        this.$emit("reload");
      })
      .catch((error: any) => {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.error?.message || "",
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onUpdate() {
    // if (!this.item.link) {
    //   return this.$swal.fire({
    //     icon: "warning",
    //     title: "ผิดพลาด",
    //     text: "กรุณากรอกลิงค์",
    //   });
    // }
    // const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    // const isValidURL: boolean = urlPattern.test(this.item.link);

    // if (!isValidURL) {
    //   return this.$swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     icon: "warning",
    //     text: "กรุณากรอกลิงค์ให้ถูกต้อง",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    // }
    // if (!this.item.link) {
    //   return this.$swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     icon: "warning",
    //     text: "กรุณากรอกลิงค์",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    // }
    if (!this.seleted) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณาเลือกสถานะ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.image_url) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณาเพิ่มรูปภาพ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.name) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณากรอกชื่อรูปภาพ",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    if (!this.item.image_alt) {
      return this.$swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        text: "กรุณากรอก ALT",
        timer: 1500,
        showConfirmButton: false,
      });
    }
    this.loading = true;
    await this.$axios
      .patch(`/admin/api/v1/carousel/${this.id}`, {
        name: this.item.name,
        link: this.item.link,
        image_url: this.item.image_url,
        image_alt: this.item.image_alt,
        status: this.seleted.value,
      })
      .then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "แก้ไข Carousel สำเร็จ",
          timer: 1500,
          showConfirmButton: false,
        });
        this.$emit("reload");
      })
      .catch((error: any) => {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: error?.response?.data?.error?.message || "",
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onFileChange(e: any) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.item.image_url = resp?.data?.data?.url || "";
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
}
