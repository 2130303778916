
import FormGroup from "@/components/FormGroup.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    FormGroup,
  },
})
export default class FormModalComponent extends Vue {
  loading!: boolean;
  show!: boolean;
  id!: string;
  statusOptions!: any;
  locationOptions!: any;
  item!: {
    name: string;
    link: string;
    image_url: string;
    image_alt: string;
    sort_order: number;
    location: string;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  seletedStatus!: any;
  seletedLocation!: any;
  data() {
    return {
      loading: false,
      item: {
        name: "",
        link: "",
        image_url: "",
        image_alt: "",
        sort_order: 1,
        location: "",
        status: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      statusOptions: [
        { text: "เปิดใช้งาน", value: "ENABLED" },
        { text: "ปิดใช้งาน", value: "DISABLED" },
      ],
      locationOptions: [
        { text: "HOME", value: "HOME" },
        { text: "MEMBER_DASHBOARD", value: "MEMBER_DASHBOARD" },
        { text: "MEMBER_DEPOSIT", value: "MEMBER_DEPOSIT" },
        { text: "MEMBER_WITHDRAW", value: "MEMBER_WITHDRAW" },
        { text: "MEMBER_LOBBY", value: "MEMBER_LOBBY" },
      ],
      seletedStatus: null,
      seletedLocation: null,
    };
  }

  mounted() {
    this.initData();
  }

  async initData() {
    // this.item.name = "";
    // this.item.link = "";
    // this.item.image_url = "";
    // this.item.image_alt = "";
    // this.item.sort_order = 0;
    // this.item.location = "";
    // this.item.status = "";
    // this.item.created_at = "";
    // this.item.updated_at = "";
    // this.item.deleted_at = "";
    if (this.id) {
      this.loading = true;
      await this.getPopup();
    }
  }

  async getPopup() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/popup/${this.id}`);
      this.loading = false;
      if (resp?.status !== 200) {
        return this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          text: resp?.data?.status?.message,
          showConfirmButton: false,
        });
      } else {
        this.item = resp?.data?.data || [];
        this.seletedStatus = this.statusOptions.find(
          (option: any) => option.value === this.item.status
        );
        this.seletedLocation = this.locationOptions.find(
          (option: any) => option.value === this.item.location
        );
      }
    } catch (error: any) {
      this.loading = false;
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onUpdate() {
    this.loading = true;
    await this.$axios
      .patch(`/admin/api/v1/popup/${this.id}`, {
        name: this.item.name,
        link: this.item.link,
        image_url: this.item.image_url,
        image_alt: this.item.image_alt,
        location: this.seletedLocation.value,
        status: this.seletedStatus.value,
      })
      .then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "แก้ไข Carousel สำเร็จ",
          timer: 1500,
          showConfirmButton: false,
        });
        this.$emit("reload");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onCreate() {
    this.seletedLocation = this.locationOptions[0];
    if (!this.seletedStatus) {
      return this.$swal.fire({
        icon: "error",
        text: "โปรดระบุ สถานะ",
        confirmButtonText: "Confirm",
      });
    }
    if (this.item.name == "") {
      return this.$swal.fire({
        icon: "error",
        text: "โปรดระบุ Title",
        confirmButtonText: "Confirm",
      });
    }
    if (this.item.image_alt == "") {
      return this.$swal.fire({
        icon: "error",
        text: "โปรดระบุ Alt ",
        confirmButtonText: "Confirm",
      });
    }
    if (this.item.image_url == "") {
      return this.$swal.fire({
        icon: "error",
        text: "กรุณา Upload รูป ",
        confirmButtonText: "Confirm",
      });
    }
    this.loading = true;
    await this.$axios
      .post(`/admin/api/v1/popup`, {
        name: this.item.name,
        link: this.item.link,
        image_url: this.item.image_url,
        image_alt: this.item.image_alt,
        location: this.seletedLocation.value,
        status: this.seletedStatus.value,
      })
      .then(() => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "แก้ไข Carousel สำเร็จ",
          timer: 1500,
          showConfirmButton: false,
        });
        this.$emit("reload");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async onFileChange(e: any) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    const formData = new FormData();
    formData.append("file", files[0]);
    this.loading = true;
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      this.loading = false;
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item.image_url = resp?.data?.data?.url || "";
      }
    } catch (error: any) {
      this.loading = false;
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
}
