
import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    FormGroup,
    Switch,
  },
})
export default class SettingWebContentComponent extends Vue {
  item: any;
  languages!: any;
  sections!: Array<string>;
  loading!: boolean;
  showEditor!: boolean;
  pageContent: any = {
    one: {
      title: "คาสิโนที่ใส่ใจคุณสนุกได้ทั้งแนวตั้งและแนวนอน",
      content:
        "{name} ใส่ใจทุกรายละเอียด ไม่ว่าคุณจะเล่นผ่านเว็บไซต์ หรือ แอฟในมือถือ ต่างได้ประสบการณ์สุดพิเศษ ที่ใช้งานง่าย เสถียร์ ให้คุณมีเวลา และ ทำกำไรปราศจากความกังวล ท่ามกลางเว็บ {name} Casino มากมาย เล่นทั้งที ต้องเล่นเว็บตรง",
      list: [
        "เว็บตรงไม่โอนย้าย สมัครภายใน 1 นาที คุย/ไม่คุย ก็เล่นได้ชิล",
        "การเงินมั่นคงฝากถอน ถอนสูงสุดบัญชีละ 2 ล้าน ต่อวัน",
        "ผู้นำตัวจริง ระบบออโต้ ฝาก-ถอน การันตีเวลา",
        "ทีมงานมืออาชีพ พร้อมดูแลคุณตลอด 24 ชั่วโมง",
      ],
      list1: "เว็บตรงไม่โอนย้าย สมัครภายใน 1 นาที คุย/ไม่คุย ก็เล่นได้ชิล",
      list2: "การเงินมั่นคงฝากถอน ถอนสูงสุดบัญชีละ 2 ล้าน ต่อวัน",
      list3: "ผู้นำตัวจริง ระบบออโต้ ฝาก-ถอน การันตีเวลา",
      list4: "ทีมงานมืออาชีพ พร้อมดูแลคุณตลอด 24 ชั่วโมง",
    },
    two: {
      title: "ทำไม่ต้อง {name}",
      content: "",
      list: [
        "ระบบอัตโนมัติ ฝากถอนเร็ว เพียงไม่กี่วินาที",
        "เว็บเดียวจบครบทุกการเดิมพัน ไม่ว่าจะเป็น บาคาร่า สล็อต กีฬา",
        "เปิดให้บริการไม่ต่ำกว่า 5 ปี",
        "ฐานข้อมูลตั้งอยู่ในต่างประเทศ ปลอดภัย 100%",
        "เล่นได้ ถอนเงินได้จริง สูงสุด 1,000,000 บาทต่อวัน",
        "คาสิโนสด ส่งตรงจากคาสิโน ไม่มีการตัดต่อ",
        "มีตัวตนจริง สถานที่ตั้งจริง ในคาสิโนต่างประเทศ",
        "ไม่มีขั้นต่ำ",
      ],
      list1: "ระบบอัตโนมัติ ฝากถอนเร็ว เพียงไม่กี่วินาที",
      list2: "เว็บเดียวจบครบทุกการเดิมพัน ไม่ว่าจะเป็น บาคาร่า สล็อต กีฬา",
      list3: "เปิดให้บริการไม่ต่ำกว่า 5 ปี",
      list4: "ฐานข้อมูลตั้งอยู่ในต่างประเทศ ปลอดภัย 100%",
      list5: "เล่นได้ ถอนเงินได้จริง สูงสุด 1,000,000 บาทต่อวัน",
      list6: "คาสิโนสด ส่งตรงจากคาสิโน ไม่มีการตัดต่อ",
      list7: "มีตัวตนจริง สถานที่ตั้งจริง ในคาสิโนต่างประเทศ",
      list8: "ไม่มีขั้นต่ำ",
    },
  };
  contentCenter: any = {
    title:
      "เว็บที่ให้บริการคาสิโนออนไลน์เต็มรูปแบบ ทั้งสล็อตออนไลน์ คาสิโนสด บาคาร่าสด เกมแทงปลา เกมกีฬา ฯลฯ",
    description:
      "โดยผู้เล่นสามารถสนุกกับเกมขอเราได้ด้วยเงินเดิมพันขั้นต่ำเพียง 1 บาทเท่านั้นและสามารถทำการ ฝาก-ถอนได้ตลอด 24 ชั่วโมง ด้วยระบบออโต้ ทั้งสะดวกรวดเร็วทันใจเป็นอย่างมากเหมาะสำหรับ ผู้ที่ชื่นชอบการเดิมพันในรูปแบบของเกมการพนันออนไลน์อันดับ 1 ในปี 2034",
  };
  contentItem!: any;
  sections_lang!: any;
  tags_lang!: any;
  isActiveLang!: any;
  indexSelect!: any;
  tags!: Array<string>;
  isSectionType!: any;
  isSectionLang!: any;
  data() {
    return {
      item: {
        languages: {
          thai: false,
          // english: false,
          // chinese: false,
          // cambodian: false,
          // lao: false,
          // myanmar: false,
          // vietnamese: false,
          // malay: false,
          // indonesian: false,
          // singaporean: false,
          // japanese: false,
          // korean: false,
        },
        marquee_text: {
          cambodian: "",
          chinese: "",
          english: "",
          indonesian: "",
          japanese: "",
          korean: "",
          lao: "",
          malay: "",
          myanmar: "",
          singaporean: "",
          thai: "",
          vietnamese: "",
        },
        custom_login_url: "",
        custom_register_url: "",
        v1_domain: "",
      },
      loading: false,
      showEditor: false,
      sections: [
        {
          name: "MainContent",
          title: this.contentCenter.title,
          description: this.contentCenter.description,
          list: [],
        },
        {
          name: "Hero",
          title: "Hero",
          description: "MainContent",
          list: [],
        },
        {
          name: "Custom1",
          title: this.pageContent.one.title,
          description: this.pageContent.one?.content,
          list: this.pageContent.one?.list,
        },
        {
          name: "Custom2",
          title: this.pageContent.two.title,
          description: this.pageContent.two?.content,
          list: this.pageContent.two?.list,
        },
      ],
      sections_lang: {
        thai: {
          MainContent: {
            title: "thai" + this.contentCenter.title,
            description: "thai" + this.contentCenter.description,
            list: [],
          },
          Hero: {
            title: "thai" + this.contentCenter.title,
            description: "thai" + this.contentCenter.description,
            list: [],
          },
          Custom1: {
            title: "thai" + this.pageContent.one.title,
            description: "thai" + this.pageContent.one?.content,
            list: this.pageContent.one?.list,
          },
          Custom2: {
            title: "thai" + this.pageContent.two.title,
            description: "thai" + this.pageContent.two?.content,
            list: this.pageContent.two?.list,
          },
        },
        english: {
          MainContent: {
            title: "english" + this.contentCenter.title,
            description: "english" + this.contentCenter.description,
            list: [],
          },
          Hero: {
            title: "english" + this.contentCenter.title,
            description: "english" + this.contentCenter.description,
            list: [],
          },
          Custom1: {
            title: "english" + this.pageContent.one.title,
            description: "english" + this.pageContent.one?.content,
            list: this.pageContent.one?.list,
          },
          Custom2: {
            title: "english" + this.pageContent.two.title,
            description: "english" + this.pageContent.two?.content,
            list: this.pageContent.two?.list,
          },
        },
        chinese: {
          MainContent: {
            title: "chinese" + this.contentCenter.title,
            description: "chinese" + this.contentCenter.description,
            list: [],
          },
          Hero: {
            title: "chinese" + this.contentCenter.title,
            description: "chinese" + this.contentCenter.description,
            list: [],
          },
          Custom1: {
            title: "chinese" + this.pageContent.one.title,
            description: "chinese" + this.pageContent.one?.content,
            list: this.pageContent.one?.list,
          },
          Custom2: {
            title: "chinese" + this.pageContent.two.title,
            description: "chinese" + this.pageContent.two?.content,
            list: this.pageContent.two?.list,
          },
        },
      },
      languages: [],
      isActive: false,
      isType: 0,
      isActiveLang: 0,
      indexSelect: "thai",
      tags_lang: {},
      tags: [],
      section: ["main", "custom1", "custom2", "footer"],
      isSectionType: "",
      isSectionLang: "",
    };
  }

  mounted() {
    this.getData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/general`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item = resp?.data?.data || null;
        this.initData();
        this.getDataSeo();
        this.getDataContent();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  initData() {
    let item = this.item;
    this.languages = [];
    for (const [key, value] of Object.entries(item.languages)) {
      if (value) {
        this.languages.push({
          name: key,
          isActive: false,
          isTagActive: false,
          isSectionActive: false,
        });
      }
    }
    this.languages[0].isActive = true;
    this.languages[0].isTagActive = true;
    this.langActive("tag", 0);
    this.langActive("section", 0);
    this.langActive("marquee", 0);
  }

  async onSubmit() {
    this.onSubmitSeo();
    return;
  }
  async onFileChange(e: any) {
    var files = e.target.files || e.dataTransfer.files;
    const fileName = files[0].name;
    if (!files.length) return;
    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const resp = await this.$axios.post(
        `/admin/api/v1/media/upload`,
        formData
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.item.line_icon.url = resp?.data?.data?.url || "";
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async getDataSeo() {
    this.item.marquee_text = {
      cambodian: "",
      chinese: "",
      english: "",
      indonesian: "",
      japanese: "",
      korean: "",
      lao: "",
      malay: "",
      myanmar: "",
      singaporean: "",
      thai: "",
      vietnamese: "",
    };
    try {
      const resp = await this.$axios.get("/admin/api/v1/setting/seo");
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item.marquee_enabled = resp?.data?.data?.marquee_enabled || null;
        this.item.marquee_text = resp?.data?.data?.marquee_text || null;
        this.tags_lang = resp?.data?.data?.tags || null;
        this.langActive("tag", 0);
        this.langActive("marquee", 0);
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  async onSubmitSeo() {
    let item = this.item;
    item.tags = this.tags_lang;
    try {
      const resp = await this.$axios.patch(
        "/admin/api/v1/setting/seo",
        this.item
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.onSubmitTag();
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  trungcate_text(text: any) {
    const length = 10;
    const suffix = "...";
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  }
  async submitEditor() {
    let item = this.item.content;
    for (const [key, value] of Object.entries(item.custom1)) {
      let description = {
        content: item.custom1[key].content,
        list: item.custom1[key].list,
      };
      item.custom1[key] = {
        title: item.custom1[key].title,
        description: JSON.stringify(description),
      };
    }
    for (const [key, value] of Object.entries(item.custom2)) {
      let description = {
        content: item.custom2[key].content,
        list: item.custom2[key].list,
      };
      item.custom2[key] = {
        title: item.custom2[key].title,
        description: JSON.stringify(description),
      };
    }
    for (const [key, value] of Object.entries(item.footer)) {
      item.footer[key] = {
        title: "",
        description: JSON.stringify({
          welcome: item.footer[key].welcome,
          partnership: item.footer[key].partnership,
          payment: item.footer[key].payment,
          truewallet: item.footer[key].truewallet,
        }),
      };
    }
    try {
      const resp = await this.$axios.patch("/admin/api/v1/setting/content", {
        custom1: item.custom1,
        custom2: item.custom2,
        main: item.main,
        hero: item.footer,
      });
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        let str = JSON.stringify(this.languages, (k, v) =>
          v === true ? false : v
        );
        this.languages = JSON.parse(str);
        this.showEditor = false;
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  setContentItem() {
    this.contentItem =
      this.item.content[this.isSectionType][this.isSectionLang];
  }
  async changeLangEditor(type: any) {
    this.languages[type].isSectionActive = true;
    let str = JSON.stringify(this.languages, (k, v) =>
      v === true ? false : v
    );
    this.languages = JSON.parse(str);
    this.isSectionLang = this.languages[type].name;
    this.contentItem =
      this.item.content[this.isSectionType][this.isSectionLang];
    if (this.isSectionType == "custom1" || this.isSectionType == "custom2") {
      try {
        let convertdes = JSON.parse(this.contentItem.description);
        this.contentItem.description =
          convertdes && convertdes.content ? convertdes.content : "";
        this.contentItem.list =
          convertdes && convertdes.list ? convertdes.list : [];
      } catch (e) {
        this.contentItem =
          this.item.content[this.isSectionType][this.isSectionLang];
      }
    } else if (this.isSectionType == "footer") {
      this.contentItem =
        this.item.content[this.isSectionType][this.isSectionLang];
    } else {
      this.contentItem =
        this.item.content[this.isSectionType][this.isSectionLang];
    }

    this.languages[type].isSectionActive = true;
  }
  tagEnter(e: any) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        this.tags.push(e.target.value);
        e.target.value = "";
      }
      console.log("Enter was pressed", e);
    } else if (e.keyCode === 50) {
      console.log("@ was pressed");
    }
  }
  removeTag(index: any) {
    this.tags.splice(index, 1);
  }
  async onSubmitTag() {
    for (let index = 0; index < this.languages.length; index++) {
      if (this.languages[index].isTagActive) {
        this.tags_lang[this.languages[index].name] = this.tags;
      }
    }
    return;
  }
  langActive(type: any, index: any) {
    if (type == "tag") {
      for (let index = 0; index < this.languages.length; index++) {
        this.languages[index].isTagActive = false;
      }
      this.languages[index].isTagActive = true;
      this.tags = this.tags_lang[this.languages[index].name];
    }
    if (type == "section") {
      for (let index = 0; index < this.languages.length; index++) {
        this.languages[index].isSectionActive = false;
      }
      this.languages[index].isSectionActive = true;
      this.contentItem = {};
    }
    if (type == "marquee") {
      for (let index = 0; index < this.languages.length; index++) {
        this.languages[index].isActiveMarquee = false;
      }
      this.languages[index].isActiveMarquee = true;
      this.tags = this.tags_lang[this.languages[index].name];
    }
  }
  getImageUrl = (name: string) => {
    let images = "";
    images = require("../assets/image/" + name + ".png");
    if (!images) {
      require("../assets/logo.png");
    }
    return images;
  };
  async getDataContent() {
    this.item.content = {
      main: {
        thai: {
          title: "",
          content: "",
          list: [],
        },
      },
      footer: {
        thai: {
          welcome: "",
          partnership: "",
          payment: "",
          truewallet: "",
        },
      },
      custom1: {
        thai: {
          title: "",
          content: "",
          list: [],
        },
      },
      custom2: {
        thai: {
          title: "",
          content: "",
          list: [],
        },
      },
    };
    try {
      const resp = await this.$axios.get("/admin/api/v1/setting/content");
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        if (Object.keys(resp.data.data).length === 0) {
          let custom11: any = resp?.data?.data?.custom1 || {};
          let custom22: any = resp?.data?.data?.custom2 || {};
          let footer1: any = resp?.data?.data?.hero || {};
          let main1: any = resp?.data?.data?.main || {};
          for (const [key, value] of Object.entries(this.item.languages)) {
            if (value) {
              custom11[key] = {
                title: "",
                content: "",
                list: [],
              };
              custom22[key] = {
                title: "",
                content: "",
                list: [],
              };
              footer1[key] = {
                welcome: "",
                partnership: "",
                payment: "",
                truewallet: "",
              };
              main1[key] = {
                title: "",
                content: "",
              };
            }
          }
          this.item.content = {
            main: main1 || null,
            footer: footer1 || null,
            custom1: custom11 || null,
            custom2: custom22 || null,
          };
        } else {
          let custom1: any = resp?.data?.data?.custom1;
          for (const [key, value] of Object.entries(custom1)) {
            if (custom1[key]) {
              let datacustom1 = custom1[key];
              if (datacustom1.title) {
                custom1[key].title = datacustom1.title;
              }
              if (datacustom1.description) {
                let convertdes = JSON.parse(datacustom1.description);
                custom1[key].content = convertdes.content
                  ? convertdes?.content
                  : "";
                custom1[key].list = convertdes.list ? convertdes?.list : [];
              }
            }
          }
          let custom2: any = resp?.data?.data?.custom2;
          for (const [key, value] of Object.entries(custom1)) {
            if (custom2[key]) {
              let datacustom2 = custom2[key];
              if (datacustom2.title) {
                custom2[key].title = datacustom2.title;
              }
              if (datacustom2.description) {
                let convertdes = JSON.parse(datacustom2.description);
                custom2[key].content = convertdes.content
                  ? convertdes?.content
                  : "";
                custom2[key].list = convertdes.list ? convertdes?.list : [];
              }
            }
          }
          let footer: any = resp?.data?.data?.hero;
          for (const [key, value] of Object.entries(footer)) {
            try {
              let convertdes = JSON.parse(footer[key].description);
              footer[key] = {
                welcome:
                  convertdes && convertdes.welcome ? convertdes.welcome : "",
                partnership:
                  convertdes && convertdes.partnership
                    ? convertdes.partnership
                    : "",
                payment:
                  convertdes && convertdes.payment ? convertdes.payment : "",
                truewallet:
                  convertdes && convertdes.truewallet
                    ? convertdes.truewallet
                    : "",
              };
            } catch (e) {
              footer[key] = {
                welcome: "",
                partnership: "",
                payment: "",
                truewallet: "",
              };
            }
          }
          this.item.content = {
            main: resp?.data?.data?.main || null,
            footer: footer || null,
            custom1: custom1 || null,
            custom2: custom2 || null,
          };
        }
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
  addList() {
    this.item.content[this.isSectionType][this.isSectionLang].list.splice(
      0,
      0,
      ""
    );
  }
  removeList(i: any) {
    this.item.content[this.isSectionType][this.isSectionLang].list.splice(i, 1);
  }
}
