
import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Switch,
    FormGroup,
  },
})
export default class SettingWebGameComponent extends Vue {
  isDragging!: boolean;
  item: any;
  categorySort!: any;
  highlightSort!: any;
  loading!: boolean;

  sectionGames!: Array<{
    id: number;
    text: string;
    isActive: boolean;
  }>;
  sectionHighlightGames!: Array<{
    id: number;
    text: string;
    isActive: boolean;
  }>;
  maintenanceIsActive!: boolean;
  maintenanceText!: string;

  categorys!: any;
  highlights!: any;

  seletedTypeGame!: any;
  seletedTypeGameMobile!: any;
  typeGameDisplay!: any;
  gl = [
    {
      name_th: "ไพ่",
      key_name: "category_enabled_card",
      key_sort_name: "Card",
    },
    {
      name_th: "คาสิโน",
      key_name: "category_enabled_casino",
      key_sort_name: "Casino",
    },
    {
      name_th: "อีสปอร์ต",
      key_name: "category_enabled_esport",
      key_sort_name: "Esport",
    },
    {
      name_th: "ยิงปลา",
      key_name: "category_enabled_fish",
      key_sort_name: "Fish",
    },
    {
      name_th: "หวย",
      key_name: "category_enabled_lotto",
      key_sort_name: "Lotto",
    },
    {
      name_th: "สล๊อต",
      key_name: "category_enabled_slot",
      key_sort_name: "Slot",
    },
    {
      name_th: "กีฬา",
      key_name: "category_enabled_sport",
      key_sort_name: "Sport",
    },
  ];
  data() {
    return {
      isDragging: false,
      loading: false,
      sectionGames: [
        { id: 1, text: "สล็อต", isActive: true },
        { id: 2, text: "คาสิโน", isActive: true },
        { id: 3, text: "กีฬา", isActive: true },
      ],
      sectionHighlightGames: [
        { id: 1, text: "เกมยอดนิยม", isActive: true },
        { id: 2, text: "เกมใหม่ล่าสุด", isActive: true },
        { id: 3, text: "เกมแนะนำ", isActive: true },
      ],
      item: {
        category_enabled_slot: false,
        category_enabled_casino: false,
        category_enabled_sport: false,
        category_sort: [],
        highlight_enabled_popular: false,
        highlight_enabled_latest: false,
        highlight_enabled_recommended: false,
        highlight_sort: [],
      },
      categorySort: [],
      highlightSort: [],
      maintenanceIsActive: true,
      maintenanceText: "ปิดปรับปรุง",

      categorys: [],
      highlights: [],

      typeGameDisplay: [
        { text: "แสดงทั้งหมด", value: 6 },
        { text: "สไลด์", value: 1 },
        { text: "แนวตั้ง ปุ่มยาว", value: 3 },
        { text: "แนวตั้ง ปุ่มจัตุรัส", value: 5 },
        { text: "แนวนอน ปุ่มยาว", value: 2 },
        { text: "แนวนอน ปุ่มจัตุรัส", value: 4 },
      ],
      seletedTypeGame: { text: "แสดงทั้งหมด", value: 6 },
      seletedTypeGameMobile: { text: "แสดงทั้งหมด", value: 6 },
    };
  }
  mounted() {
    this.getData();
    this.initData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get(`/admin/api/v1/setting/game-ui`);
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.item = resp?.data?.data || null;
        this.initData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  initData() {
    let item = this.item;
    this.categorySort = item.category_sort;
    if (this.categorySort.length == 0) {
      for (let index = 0; index < this.gl.length; index++) {
        this.categorySort.push(this.gl[index].key_sort_name);
      }
    }

    this.highlightSort = item.highlight_sort;
    const categorySortEntries = Object.entries(item).sort(([keyA], [keyB]) => {
      const indexA = this.categorySort.indexOf(keyA);
      const indexB = this.categorySort.indexOf(keyB);
      return indexA - indexB;
    });

    const hightLightSortEntries = Object.entries(item).sort(
      ([keyA], [keyB]) => {
        const indexA = this.highlightSort.indexOf(keyA);
        const indexB = this.highlightSort.indexOf(keyB);
        return indexA - indexB;
      }
    );

    this.categorys = [];
    for (let index = 0; index < this.categorySort.length; index++) {
      const items = this.categorySort[index];
      let dd = this.gl.find((option: any) => option.key_sort_name === items);
      if (dd) {
        this.categorys.push({
          text: dd.name_th,
          isActive: this.item[dd.key_name] || false,
        });
      }
    }

    // hightlight
    this.highlights = [];
    for (let index = 0; index < hightLightSortEntries.length; index++) {
      const items = hightLightSortEntries[index];
      if (items[0] === "highlight_enabled_popular") {
        this.highlights.push({
          text: "เกมยอดนิยม",
          isActive: items[1] || false,
        });
      }
      if (items[0] === "highlight_enabled_latest") {
        this.highlights.push({
          text: "เกมใหม่ล่าสุด",
          isActive: items[1] || false,
        });
      }
      if (items[0] === "highlight_enabled_recommended") {
        this.highlights.push({
          text: "เกมแนะนำ",
          isActive: items[1] || false,
        });
      }
    }

    // type display game
    if (this.item.game_bg_style) {
      this.seletedTypeGame = this.typeGameDisplay.find(
        (item: any) => item.value === this.item.game_bg_style
      );
    }
    // type display game
    if (this.item.game_bg_style_mobile) {
      this.seletedTypeGameMobile = this.typeGameDisplay.find(
        (item: any) => item.value === this.item.game_bg_style_mobile
      );
    }
  }

  async onSubmit() {
    this.loading = true;
    this.item.category_sort = [];
    this.item.highlight_sort = [];
    for (let index = 0; index < this.categorys.length; index++) {
      const cata = this.categorys[index];
      let dd = this.gl.find((option: any) => option.name_th === cata.text);
      if (dd) {
        this.item.category_sort.push(dd.key_sort_name);
        this.item[dd.key_name] = cata.isActive;
      }
    }
    this.item.game_bg_style = this.seletedTypeGame.value;
    this.item.game_bg_style_mobile = this.seletedTypeGameMobile.value;

    for (let index = 0; index < this.highlights.length; index++) {
      const hightlight = this.highlights[index];
      if (hightlight.text === "เกมยอดนิยม") {
        this.item.highlight_sort.push("Popular");
        this.item.highlight_enabled_popular = hightlight.isActive;
      }
      if (hightlight.text === "เกมใหม่ล่าสุด") {
        this.item.highlight_sort.push("Latest");
        this.item.highlight_enabled_latest = hightlight.isActive;
      }
      if (hightlight.text === "เกมแนะนำ") {
        this.item.highlight_sort.push("Recommended");
        this.item.highlight_enabled_recommended = hightlight.isActive;
      }
    }
    for (let index = 0; index < this.highlights.length; index++) {
      const hightlight = this.highlights[index];
    }
    try {
      const resp = await this.$axios.patch(
        `/admin/api/v1/setting/game-ui`,
        this.item
      );
      if (resp?.status !== 200) {
        this.loading = false;
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
        console.log(resp?.status);
      } else {
        this.loading = false;
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.loading = false;
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  onDragStart(event: any, index: any) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("startIndex", index);
  }
  onDrop(event: any, items: any, endIndex: any) {
    const startIndex = event.dataTransfer.getData("startIndex");
    var raw = items[startIndex];
    items[startIndex] = items[endIndex];
    items[endIndex] = raw;
  }
}
