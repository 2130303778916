
import FormGroup from "@/components/FormGroup.vue";
import SettingWebAppearance from "@/components/SettingWebAppearance.vue";
import SettingWebCarousel from "@/components/SettingWebCarousel.vue";
import SettingWebGame from "@/components/SettingWebGame.vue";
import SettingWebGeneral from "@/components/SettingWebGeneral.vue";
import SettingWebImagePopup from "@/components/SettingWebImagePopup.vue";
import SettingWebContent from "@/components/SettingWebContent.vue";
import LayoutDefault from "@/layouts/default.vue";
import { Switch } from "@headlessui/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LayoutDefault,
    FormGroup,
    SettingWebGame,
    SettingWebGeneral,
    SettingWebAppearance,
    SettingWebCarousel,
    SettingWebImagePopup,
    SettingWebContent,
    Switch,
  },
})
export default class SettingView extends Vue {
  isType!: number;
  isDragging!: boolean;
  itemAppearance: any;

  webDetial!: {
    name: string;
    keyword: string;
    title: string;
    description: string;
  };
  lineDetail!: {
    id: string;
    url: string;
    image: any;
    imageDetail: {
      text: string;
      title: string;
      caption: string;
      description: string;
    };
    isActive: boolean;
  };
  languages!: Array<{
    name: string;
    isActive: boolean;
  }>;
  google!: {
    analytic: {
      text: string;
      isActive: boolean;
    };
    tag: {
      text: string;
      isActive: boolean;
    };
    console: {
      text: string;
      isActive: boolean;
    };
  };
  color!: {
    main: string;
    sub: string;
    title: string;
    text: string;
  };
  sections!: Array<{
    id: number;
    text: string;
  }>;
  background!: {
    desktop: {
      text: string;
      title: string;
      caption: string;
      description: string;
    };
    mobile: {
      text: string;
      title: string;
      caption: string;
      description: string;
    };
  };
  logoIcons!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  menus!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  newGames!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  bandGames!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  registers!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  others!: Array<{
    header: string;
    text: string;
    title: string;
    caption: string;
    description: string;
  }>;
  slideIsActive!: boolean;
  popupIsActive!: boolean;

  data() {
    return {
      itemAppearance: null,
      isType: 0,
      isDragging: false,

      webDetial: {
        name: "AllxBet",
        keyword: "ALLxBet คาสิโนออนไลน์, สล๊อตแตกง่าย, สล๊อตออยไลน์, บาคาร่า",
        title: "ALLxBet คาสิโนออนไลน์ บาคาร่า สล๊อตแตกง่ายที่สุดในไทย",
        description: "ALLxBet คาสิโนออนไลน์ บาคาร่า สล๊อตแตกง่ายที่สุดในไทย",
      },
      lineDetail: {
        id: "",
        url: "",
        image: null,
        imageDetail: {
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        isActive: false,
      },
      languages: [
        { name: "ไทย", isActive: true },
        { name: "อังกฤษ", isActive: false },
        { name: "จีน", isActive: false },
        { name: "กัมพูชา", isActive: false },
        { name: "ลาว", isActive: true },
        { name: "พม่า", isActive: false },
        { name: "เวียดนาม", isActive: false },
        { name: "มาเลเซีย", isActive: false },
        { name: "อินโดเนเซีย", isActive: true },
        { name: "สิงคโปร์", isActive: true },
        { name: "ญี่ปุ่น", isActive: false },
        { name: "เกาหลี", isActive: false },
      ],
      google: {
        analytic: {
          text: "G-5BLPZ81968",
          isActive: true,
        },
        tag: {
          text: "GMT-53GCJ9T",
          isActive: true,
        },
        console: {
          text: "G-PTR524098",
          isActive: true,
        },
      },
      color: {
        main: "rgb(252, 19, 19)",
        sub: "rgb(252, 19, 19)",
        title: "rgb(252, 19, 19)",
        text: "rgb(252, 19, 19)",
      },
      sections: [
        { id: 1, text: "Content Center" },
        { id: 2, text: "เกมไฮโลท์" },
        { id: 3, text: "หมวดเกม" },
        { id: 4, text: "รูปภาพเดี่ยว" },
        { id: 5, text: "Content Group" },
        { id: 6, text: "โปรโมชั่น" },
        { id: 7, text: "บทความ" },
        { id: 8, text: "Content + Image" },
        { id: 9, text: "Image + Content" },
      ],
      background: {
        desktop: {
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        mobile: {
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      },
      logoIcons: [
        {
          header: "โลโก้ (800x190)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "Favicon (32x32)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "คือยอดเสีย (312x312)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "แนะนำเพื่อน (312x312)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "วงล้อ (312x312)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เข้าเล่นเกม (460x460)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      menus: [
        {
          header: "เมนูสล็อต (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูคาสิโน (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูกีฬา (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูยิงปลา (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูหวย (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูโปรโมชั่น (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูบทความ (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "เมนูติดต่อเรา (212x164)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      newGames: [
        {
          header: "พื้นหลังเกมใหม่ล่าสุด (1632x459)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "รูปภาพเกมใหม่ล่าสุด (443x383)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      bandGames: [
        {
          header: "ค่ายเกมกีฬา (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "ค่ายเกมสล็อต (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "ค่ายเกมคาสิโน (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "ค่ายเกมยิงปลา (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      registers: [
        {
          header: "เบอร์มือถือ (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "ข้อมูลธนาคาร (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "รหัสผ่าน (179x114)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      others: [
        {
          header: "รูปภาพเดี่ยว (1112x511)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "Content Footer #1 (343x638)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
        {
          header: "Content Footer #2 (815x652)",
          text: "",
          title: "",
          caption: "",
          description: "",
        },
      ],
      slideIsActive: true,
      popupIsActive: true,
    };
  }
  mounted() {
    // /
  }

  onFileChange(e: any) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.lineDetail.image = files[0];
  }

  onDragStart(event: any, index: any) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("startIndex", index);
  }
  onDrop(event: any, items: any, endIndex: any) {
    const startIndex = event.dataTransfer.getData("startIndex");
    var raw = items[startIndex];
    items[startIndex] = items[endIndex];
    items[endIndex] = raw;
  }
}
