
import LayoutDefault from "@/layouts/default.vue";
import { Options, Vue } from "vue-class-component";

import FormGroup from "@/components/FormGroup.vue";
import { Switch } from "@headlessui/vue";

@Options({
  components: {
    FormGroup,
    LayoutDefault,
    Switch,
  },
})
export default class SeoWebPageTextView extends Vue {
  isActive!: boolean;
  isType!: number;
  item!: {
    marquee_enabled: boolean;
    marquee_text: {
      cambodian: string;
      chinese: string;
      english: string;
      indonesian: string;
      japanese: string;
      korean: string;
      lao: string;
      malay: string;
      myanmar: string;
      singaporean: string;
      thai: string;
      vietnamese: string;
    };
  };
  data() {
    return {
      item: {
        marquee_enabled: false,
        marquee_text: {
          cambodian: "",
          chinese: "",
          english: "",
          indonesian: "",
          japanese: "",
          korean: "",
          lao: "",
          malay: "",
          myanmar: "",
          singaporean: "",
          thai: "",
          vietnamese: "",
        },
      },

      isActive: false,
      isType: 0,
    };
  }

  mounted() {
    this.getData();
  }

  async getData() {
    try {
      const resp = await this.$axios.get("/admin/api/v1/setting/seo");
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.item = resp?.data?.data || null;
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }

  async onSubmit() {
    try {
      const resp = await this.$axios.patch(
        "/admin/api/v1/setting/seo",
        this.item
      );
      if (resp?.status !== 200) {
        this.$store.dispatch("swalToast", {
          icon: "warning",
          text: resp?.data?.status?.message,
        });
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          text: "Update Success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.getData();
      }
    } catch (error: any) {
      this.$swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.error?.message || "",
      });
    }
  }
}
